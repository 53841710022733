import {AuthenticationResult, InteractionRequiredAuthError, PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./authConfig";

export const getAuthenticationToken = async (): Promise<string> => {
    const myMSALObj = new PublicClientApplication(msalConfig);
    const currentAccounts = myMSALObj.getAllAccounts();
    if (!(currentAccounts != null && currentAccounts.length > 0)) {
        throw new Error("Can not perform graphql request. No user is signed in.");
    }
    const tokenRequest = {
        scopes: ["e973d10d-a36f-4b66-8e53-31804e652b78/.default"],
        account: currentAccounts[0]
    }

    let authResult: AuthenticationResult | null = null
    try {
        authResult = await myMSALObj.acquireTokenSilent(tokenRequest)
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            console.warn("silent token acquisition fails. acquiring token using redirect")
            try {
                authResult = await myMSALObj.acquireTokenPopup(tokenRequest)
            } catch (error) {
                console.error(error);
            }
        } else {
            console.warn(error);
        }
    }

    if (!(authResult != null)) {
        throw new Error("Can not perform graphql request. User is not authenticated.")
    }
    return authResult.accessToken
}
