import * as React from 'react';

import {
    Box,
    Text
  } from "@chakra-ui/react"

export const UsersPage = () => {
  return (
      <Box textAlign="center" fontSize="xl" bg="green.100">
        <Text>Users Page</Text>
      </Box>
  );
}
