import * as React from 'react';
import {ElementImportResult} from "../../../service/elements/ElementsFileUploadService";
import {
    Text,
    Box,
    Stack,
    ListItem,
    UnorderedList,
    Heading, SimpleGrid
} from "@chakra-ui/react"

export interface ElementsImportResultsDialogProps {
    createdElementsCount: number,
    updatedElementsCount: number,
    failedFilesCount: number,
    failedFilesImportResults: ElementImportResult[]
}

export const ElementsImportResultsDialog =
    ({createdElementsCount, updatedElementsCount, failedFilesCount, failedFilesImportResults}: ElementsImportResultsDialogProps) => {
        return (
            <Box>
                <Stack spacing={5}>
                    <SimpleGrid columns={3} spacing={10}>
                        <Text align="center">
                            <b>{createdElementsCount}</b> elements created
                        </Text>
                        <Text align="center">
                            <b>{updatedElementsCount}</b> elements updated
                        </Text>
                        <Text align="center">
                            <b>{failedFilesCount}</b> {failedFilesCount === 1 ? "file is" : "files are"} invalid
                        </Text>
                    </SimpleGrid>

                    {failedFilesImportResults.length > 0 && <>
                        <Box>
                            <Heading alignContent={"center"} as="h2" mt="20px" size="sm">Invalid files:</Heading>
                            <UnorderedList mt="1rem">
                            {failedFilesImportResults.map((result) => (<>
                                <ListItem>
                                    <i>{result.fileName}</i>
                                    <UnorderedList>
                                        {result.errors.map((error) => (
                                            <ListItem color={"red.400"}>
                                                {error.errorMessage}
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </ListItem>
                            </>))}
                            </UnorderedList>
                        </Box>
                    </>}
                </Stack>
            </Box>
        )
    }