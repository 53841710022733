import {
    Box, Button,
    FormControl,
    FormLabel,
    HStack,
    IconButton,
    Spacer,
    VStack
} from "@chakra-ui/react";
import {StoreItemDemo} from "../../../graphql";
import {FieldArray, FieldArrayRenderProps} from "formik";
import {FiArrowDown, FiArrowUp, FiPlus, FiTrash} from "react-icons/fi";
import * as React from "react";
import {createRequiredValidator} from "@staffpad/webapp-common";
import {FormFieldText} from "@staffpad/webapp-common";
import {FormFieldSelect} from "@staffpad/webapp-common";
import _ from "lodash"

interface DemosFormSectionProps {
    fieldName: string
    fieldTitle: string
    l10nKeyPrefix: string
}

export const DemosFormSection = (props: DemosFormSectionProps) => {
    const {fieldName, fieldTitle, l10nKeyPrefix} = props
    return (
        <FormControl>
            <FieldArray name={fieldName}>
                {(props: FieldArrayRenderProps) => {
                    return (
                        <>
                            <FormLabel htmlFor={fieldName}>{fieldTitle}</FormLabel>
                            <VStack m={0} spacing={4} alignItems={"start"} px={2} pt={4} pb={4} width={"100%"}
                                    borderColor={"#E3E8EF"} borderRadius={"md"} borderWidth={"1px"}
                                    borderStyle={"solid"}>
                                {_.get(props.form.values, fieldName)?.map((demo: StoreItemDemo, i: number) => {
                                    return (
                                        <Box key={i} width={"100%"} px={2} alignItems={"start"}>
                                            <HStack mt="2">
                                                <FormLabel fontSize={"xl"}>Demo {i + 1}</FormLabel>
                                                <Spacer/>

                                                {i > 0 &&
                                                <IconButton borderRadius={"md"} aria-label="Move Up"
                                                            onClick={() => {
                                                                props.move(i, i - 1)
                                                            }}
                                                            icon={<FiArrowUp/>}/>
                                                }
                                                {i < _.get(props.form.values, fieldName).length - 1 &&
                                                <IconButton borderRadius={"md"} aria-label="Move Down"
                                                            onClick={() => {
                                                                props.move(i, i + 1)
                                                            }}
                                                            icon={<FiArrowDown/>}/>
                                                }
                                                <IconButton colorScheme={"red"}
                                                            borderRadius={"md"}
                                                            aria-label="Remove"
                                                            icon={<FiTrash/>}
                                                            onClick={() => {
                                                                props.remove(i)
                                                            }}/>
                                            </HStack>

                                            <VStack marginTop={2} ps={4} spacing={3} alignItems={"start"}>
                                                <FormFieldText fieldId={`${fieldName}.${i}.title`}
                                                               fieldTitle={"Title"}
                                                               isLocalized={true}
                                                               l10nStringKeyFieldName={`${props.form.values.id}/${l10nKeyPrefix}Titles.${i}`}
                                                               validatorFactory={createRequiredValidator}/>

                                                <FormFieldSelect fieldId={`${fieldName}.${i}.type`}
                                                                 fieldTitle={"Type"}
                                                                 options={[
                                                                     {value: "video", title: "Video"},
                                                                     {value: "audio", title: "Audio"}
                                                                 ]}
                                                                 validatorFactory={createRequiredValidator}/>

                                                <FormFieldText fieldId={`${fieldName}.${i}.url`}
                                                               fieldTitle={"URL"}
                                                               validatorFactory={createRequiredValidator}/>

                                            </VStack>
                                        </Box>
                                    );
                                })}
                                <Box py={4}>
                                    <Button ms={6} leftIcon={<FiPlus/>} onClick={() => {
                                        props.push({title: "", url: ""})
                                    }}>Add Demo</Button>
                                </Box>
                            </VStack>
                        </>
                    )
                }}
            </FieldArray>
        </FormControl>
    )
}