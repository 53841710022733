import axios from "axios";
import {getAuthenticationToken} from "../../security/getAuthenticationToken";
import {FileUploadResult} from "@staffpad/webapp-common"

export class FileUploadService  {

    private readonly uploadUrl: string

    constructor(uploadUrl: string) {
        this.uploadUrl = uploadUrl
    }

    async uploadFile(file: File): Promise<FileUploadResult>{
        const formData = new FormData();
        formData.append(file.name, file)

        const authToken = await getAuthenticationToken()
        const {data: {importResults}, } = await axios.post(this.uploadUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${authToken}`
            }
        })
        return (importResults as FileUploadResult[])[0]
    }
}