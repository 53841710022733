import * as React from 'react';

import {
    Box
} from "@chakra-ui/react"
import {Breadcrumb, Sidebar, SidebarItem} from "@staffpad/webapp-common"
import {Route, useParams} from "react-router-dom";
import {
    StoreItemFeaturedInput,
    StoreItemRegularInput,
    useStoreItemByIdQuery
} from "../../../graphql";
import {itemInfoFromItem} from "./SoundInfo";
import {FeaturedItemForm} from "./FeaturedItemForm";
import {RegularItemForm} from "./RegularItemForm";
import {useCallback} from "react";
import {InstrumentsPage} from "./instruments/InstrumentsPage";
import {InstrumentEditPage} from "./instruments/instrument/InstrumentEditPage";
import {SoundEditGeneralPage} from "./SoundEditGeneralPage";


type SoundEditPageUrlParams = {
    id: string;
};

const NewFeaturedItemPage = () => {
    const featuredItemInput: StoreItemFeaturedInput = {
        id: "",
        PurchaseId: [""],
        imageURL: "",
        accent: "",
        folderName: [""],
        instrumentIds: [],
        featuredDetails: {
            featuredDemos: [],
            demos: [],
            features: [],
            genre: "",
            genreIconURL: "",
            instruments: [],
            introduction: "",
            logoURL: "",
            partner: "",
            released: new Date().getFullYear(),
            size: 0,
            title: "",
            sizeUnits: "GB",
            website: "",
        }
    }
    return (
        <>
            <Box as="main" className="main-content" w="full" maxW="8xl" mx="auto">
                <Box display={{md: "flex"}}>
                    <Box pl="2rem" ml="1rem" flex="1" minW="0" pt="2rem">
                        <Breadcrumb pathItems={[
                            {name: `Sounds`, path: `/products/sounds`},
                            {name: `New Featured Sound`, path: `/sounds/new-featured`}
                        ]}/>
                        <Box p={4} width={"100%"}>
                            <FeaturedItemForm featuredItem={featuredItemInput}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const NewRegularItemPage = () => {
    const regularItemInput: StoreItemRegularInput = {
        id: "",
        PurchaseId: [""],
        imageURL: "",
        accent: "",
        folderName: [""],
        instrumentIds: [],
        regularDetails: {
            demos: [],
            description: "",
            creator: "",
            title: "",
            instrument: "",
            subtitle: "",
        }
    }
    return (
        <>
            <Box as="main" className="main-content" w="full" maxW="8xl" mx="auto">
                <Box display={{md: "flex"}}>

                    <Box pl="2rem" ml="1rem" flex="1" minW="0" pt="2rem">
                        <Breadcrumb pathItems={[
                            {name: `Sounds`, path: `/products/sounds`},
                            {name: `New Regular Sound`, path: `/sounds/new-regular`}
                        ]}/>
                        <Box p={4} width={"100%"}>
                            <RegularItemForm regularItem={regularItemInput}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const ExistingItem = () => {
    const {id} = useParams<SoundEditPageUrlParams>()
    const itemByIdQuery = useStoreItemByIdQuery(
        {
            id: id
        },
        {
            staleTime: 1000 * 60 * 3,
            onSettled: () => {
                //
            },
            onError: (error) => {
                console.error(`Error while fetching store item: ${error}`)
            },
            onSuccess: (data) => {
                //setTotalItemsCount(data.store_itemsSearch.totalCount)
            }
        }
    )

    const itemInfo = (!itemByIdQuery.isFetching && itemByIdQuery.data?.store_item != null)
        ? itemInfoFromItem(itemByIdQuery.data.store_item) : null

    const onInfoUpdated = useCallback(() => {
        itemByIdQuery.remove()
        itemByIdQuery.refetch()
    }, [itemByIdQuery])

    const sidebarItems: SidebarItem[] = [
        { title: "General", mainPath: `/sounds/${id}/general`, relatedPaths:[] },
        { title: "Instruments", mainPath: `/sounds/${id}/instruments`, relatedPaths:[] }
    ]

    return (
        <Box as="main" className="main-content" w="full" maxW="8xl" mx="auto">
            <Route path={["/sounds/:id/general", "/sounds/:id/instruments"]} exact={true}>
                <Breadcrumb pathItems={[
                    {name: `Sounds`, path: `/products/sounds`},
                    {name: `${itemInfo?.title ?? ""}`, path: `/sounds/${id}/general`}
                ]}/>
                <Box display={{ md: "flex" }}>
                    <Sidebar items={sidebarItems} />

                    { itemInfo &&
                    <>
                        <Route path={"/sounds/:id/general"} exact={true}>
                            <SoundEditGeneralPage itemInfo={itemInfo} onInfoUpdated={onInfoUpdated}/>
                        </Route>

                        <Route path="/sounds/:id/instruments" exact={true}>
                            { itemInfo && <InstrumentsPage storeItemInfo={itemInfo}/>}
                        </Route>
                    </>
                    }
                </Box>
            </Route>
            <Route path={"/sounds/:soundId/instruments/:id"}>
                { itemInfo && <InstrumentEditPage storeItemInfo={itemInfo}/> }
            </Route>
        </Box>
    )
}

export const SoundEditPage = () => {
    const {id} = useParams<SoundEditPageUrlParams>()
    if (id === "new-featured") {
        return (<NewFeaturedItemPage/>)
    } else if (id === "new-regular") {
        return (<NewRegularItemPage/>)
    } else {

    }
    return (<ExistingItem/>)
}