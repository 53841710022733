import {Breadcrumb} from "@staffpad/webapp-common"
import {
    Button,
    Center,
    CircularProgress,
    Flex, HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {FiSend, FiTrash2, FiUpload, FiXOctagon} from "react-icons/fi";
import {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import {ElementImportResult, ImportStatus} from "../../../service/elements/ElementsFileUploadService";
import _ from "lodash"
import {elementFileUploadService} from "../../../service";
import {ElementsImportResultsDialog} from "./ElementsImportResultsDialog";




export const UploadElementsPage = () => {

    const [filesToUpload, setFilesToUpload] = useState<File[]>([])
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [uploadResults, setUploadResults] = useState<ElementImportResult[] | null>(null)
    const [uploadError, setUploadError] = useState<Error | null>(null)

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setFilesToUpload((files) => {
            return _.concat(files, acceptedFiles)
        })
    }, [])

    const removeFileAtIndex = useCallback((fileIndex: number) => {
        setFilesToUpload((files) => {
            let updatedFilesArray = [...files]
            updatedFilesArray.splice(fileIndex, 1)
            return updatedFilesArray
        })
    }, [])

    const filesToUploadTable = (
        <>
            <Table variant="simple" mt="2rem">
                <Thead>
                    <Tr>
                        <Th>File</Th>
                        <Th isNumeric>Size</Th>
                        <Th />
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        filesToUpload.map((file, fileIndex) =>
                            <Tr>
                                <Td>{file.name}</Td>
                                <Td isNumeric>{file.size}</Td>
                                <Td>
                                    <Button colorScheme="red" variant="link" onClick={() => { removeFileAtIndex(fileIndex) }}>
                                        <Icon as={FiTrash2} w={5} h={5} />
                                    </Button>
                                </Td>
                            </Tr>
                        )
                    }
                </Tbody>
            </Table>
        </>
    )

    const onUploadButtonClick = useCallback(async () => {
        setIsUploading(true)
        try {
            const results = await elementFileUploadService.uploadElementFiles(filesToUpload)
            setUploadResults(results)
        } catch (error) {
            setUploadError(error)
        } finally {
            setIsUploading(false)
        }

        setFilesToUpload([])
    }, [filesToUpload])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: ".opus"
    })

    const uploadProgressModal = (
        <Modal isOpen={isUploading} onClose={()=>{}} isCentered>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>
                    <Center>
                        <Text>Uploading elements files...</Text>
                    </Center>
                </ModalHeader>
                <ModalBody p={5}>
                    <Center>
                        <CircularProgress isIndeterminate size="100px" />
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    )

    const onUploadResultsDialogClose = useCallback(() => {
        setUploadResults(null)
    }, [])

    const uploadResultsModal = () => {
        if (uploadResults == null) {
            return null
        }
        const newElementsCount = uploadResults.filter((result) => result.status === ImportStatus.created).length
        const updatedElementsCount = uploadResults.filter((result) => result.status === ImportStatus.updated).length
        const failedResults = uploadResults.filter((result) => result.status === ImportStatus.failed)
        return (
            <Modal isOpen={true} size="2xl" onClose={onUploadResultsDialogClose} isCentered scrollBehavior="inside">
                <ModalOverlay />

                <ModalContent >
                    <ModalHeader>
                        <Center>
                            <Text>Import results</Text>
                        </Center>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p={5}>
                        <ElementsImportResultsDialog createdElementsCount={newElementsCount}
                                                     updatedElementsCount={updatedElementsCount}
                                                     failedFilesCount={failedResults.length}
                                                     failedFilesImportResults={failedResults}/>
                    </ModalBody>
                </ModalContent>
            </Modal>
        )
    }


    const onUploadErrorDialogClose = useCallback(() => {
        setUploadError(null)
    }, [])

    const uploadErrorsModal = () => {
        if(uploadError == null) {return null}
        return (
            <Modal size="2xl" isOpen={true} onClose={onUploadErrorDialogClose} isCentered scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        <HStack spacing={3}>
                            <Icon as={FiXOctagon} w={10} h={10} color={"red.400"} />
                            <Text>Unexpected error during elements upload</Text>
                        </HStack>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p={5}>
                        <Text>{uploadError.message}</Text>
                    </ModalBody>
                </ModalContent>
            </Modal>
        )
    }

    return (
        <>
            <Breadcrumb pathItems={[{ name: "Elements", path: "/products/elements" }, { name: "Upload", path: "/products/elements/upload" }]} />

            {filesToUpload.length > 0 &&
                <>
                    <Flex m={4} >
                        <Spacer />
                        <Button leftIcon={<FiSend />} colorScheme="blue" variant="outline" onClick={onUploadButtonClick}>
                            Upload selected files
                        </Button>
                    </Flex>
                    <Flex m={4}>
                        {filesToUploadTable}
                    </Flex>
                </>
            }

            <Flex
                justify="center"
                align="center"
                textAlign="center"
                bg={isDragActive ? "blue.50" : "gray.50"}
                borderStyle="solid"
                borderWidth="2px"
                borderColor={isDragActive ? "blue.100" : "gray.100"}

                h={150}
                p={50}
                m={4}

                cursor="pointer"
                borderRadius={10}
                {...getRootProps()}
            >

                <input {...getInputProps()} />
                <Stack>
                    <Center>
                        <Icon as={FiUpload} w={10} h={10} color={isDragActive ? "blue.200" : "gray.200"} />
                    </Center>

                    {isDragActive ? (
                        <Text color="blue.400">Drop the files here...</Text>
                    ) : (
                        <Text color="gray.500">Drag 'n' drop <b>.opus</b> files here, or click to select files</Text>
                    )}

                </Stack>
            </Flex>

            { isUploading && uploadProgressModal }
            { (uploadResults && !uploadError) && uploadResultsModal() }
            { (uploadError) && uploadErrorsModal()}
        </>
    )
}