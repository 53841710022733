import * as React from 'react';

import {Button, VStack, FormLabel, FormControl, Text, HStack} from "@chakra-ui/react"
import {Form, Formik, FormikErrors} from 'formik';
import {FormFieldText} from "@staffpad/webapp-common";

import {
    InstrumentFieldsFragment,
    InstrumentInput,
    useUpdateOrCreateInstrumentMutation
} from "../../../../../../graphql";

interface InstrumentInfoFormProps {
    instrument: InstrumentFieldsFragment
    onSave?: (() => void) | undefined
}

export const InstrumentInfoForm = (props: InstrumentInfoFormProps) => {
    const {onSave, instrument} = props

    const versionInput: InstrumentInput = {
        id: instrument.intId,
        storeItemId: instrument.storeItemId,
        name: instrument.name,
        directoryPath: instrument.directoryPath,
        abbreviation: instrument.abbreviation,
        category: instrument.category,
        l10n: []
    }

    const validateForm = (values: InstrumentInput): FormikErrors<any> | undefined => {
        return {}
    }

    const updateMutation = useUpdateOrCreateInstrumentMutation({
        onSuccess: data => {
            console.log("Instrument successfully updated", data)
            if (onSave) {
                onSave()
            }
        },
        onError: error => {
            console.error(error)
        }
    })

    return (
        <>
            <Formik validateOnChange={false}
                    initialValues={versionInput}
                    validate={validateForm}
                    onSubmit={async (values: InstrumentInput, actions) => {
                        await updateMutation.mutateAsync({
                            instrument: values
                        })
                        actions.setSubmitting(false)
                    }}
            >
                {(props) => (
                    <Form>
                        <VStack spacing={6} alignItems={"start"}>
                            <FormControl>
                                <FormLabel>ID</FormLabel>
                                <Text>{instrument.id}</Text>
                            </FormControl>

                            <FormFieldText fieldId={"name"}
                                           fieldTitle={"Name"}/>
                            <FormFieldText fieldId={"directoryPath"}
                                           fieldTitle={"Directory Path"}/>
                            <FormFieldText fieldId={"abbreviation"}
                                           fieldTitle={"Abbreviation"}/>
                            <FormFieldText fieldId={"category"}
                                           fieldTitle={"Category"}/>

                            <HStack>
                                <Button
                                    colorScheme="blue"
                                    isLoading={props.isSubmitting}
                                    type="submit">
                                    Save
                                </Button>
                            </HStack>
                        </VStack>
                    </Form>
                )}
            </Formik>
        </>
    )
}