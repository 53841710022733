import * as React from 'react';
import { DeleteConfirmAlert, Paginator} from "@staffpad/webapp-common";
import {
    Box, Button,
    Center,
    Checkbox,
    HStack,
    IconButton,
    Input, Spacer, Spinner,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {StoreItemInfo} from "../SoundInfo";
import {
    SearchInstrumentsQuery,
    useDeleteStoreSectionsMutation,
    useSearchInstrumentsQuery
} from "../../../../graphql";
import { FiEdit, FiTrash, FiPlus} from "react-icons/fi";
import _ from "lodash";
import {useHistory} from "react-router";


interface InstrumentPageProps {
    storeItemInfo: StoreItemInfo
}

export const InstrumentsPage = (props: InstrumentPageProps) => {
    const {storeItemInfo} = props

    const [searchInputValue, setSearchInputValue] = React.useState("")
    const [querySearchValue, setQuerySearchValue] = React.useState("")
    const [selectedItemsIds, setSelectedItemsIds] = React.useState<Set<string>>(new Set())
    const [itemsPerPage] = React.useState(10)
    //const [totalItemsCount, setTotalItemsCount] = React.useState(0)
    const [currentPage, setCurrentPage] = React.useState(0)
    const deleteItemsMutation = useDeleteStoreSectionsMutation()
    const [deleteAlertIsOpen, setDeleteAlertIsOpen] = React.useState(false)

    const instrumentsQuery = useSearchInstrumentsQuery(
        {
            filter: {name: querySearchValue, storeItemId: storeItemInfo.id},
            paging: {limit: itemsPerPage, offset: (currentPage) * itemsPerPage}
        },
        {
            staleTime: 1000 * 60 * 3,
            cacheTime: 0,
            refetchOnMount: true,
            onSettled: () => {
                setSelectedItemsIds(new Set())
            },
            onError: (error: any) => {
                console.error(`Error while fetching elements: ${error}`)
            },
            onSuccess: (data: any) => {}
        }
    )

    const totalItemsCount = instrumentsQuery.data?.instruments_search.totalCount

    const onPageChange = (page: number) => {
        setCurrentPage(page)
        console.log(`current page: ${page}`)
    }

    const setQuerySearchValueDebounced = React.useMemo(() => _.debounce((value: string) => {
        setQuerySearchValue(value)
        setCurrentPage(0)
    }, 700), [])

    const searchFieldValueDidChange = (value: string) => {
        setSearchInputValue(value)
        setQuerySearchValueDebounced(value)
    }

    const onCheckboxChange = React.useCallback((itemId: string, isSelected: boolean) => {
        setSelectedItemsIds((currentIds: Set<string>) => {
            const updatedSet = new Set(currentIds)
            if (isSelected) {
                updatedSet.add(itemId)
            } else {
                updatedSet.delete(itemId)
            }
            return updatedSet
        })
    }, [])

    const onDeleteAlertIsClosed = React.useCallback((deleteConfirmed: boolean) => {
        setDeleteAlertIsOpen(false)
        if (deleteConfirmed) {

            deleteItemsMutation.mutate(
                {
                    ids: Array.from(selectedItemsIds)
                },
                {
                    onSuccess: () => {
                        instrumentsQuery.refetch()
                    }
                }
            )
            setSelectedItemsIds(new Set())
        }
    }, [selectedItemsIds, deleteItemsMutation, instrumentsQuery])

    const history = useHistory()

    const onDeleteButtonClick = React.useCallback(() => {
        setDeleteAlertIsOpen(true)
    }, [])

    const onEditButtonClick = React.useCallback((item) => {
        history.push(`/sounds/${storeItemInfo.id}/instruments/${item.id}/info`)
    }, [history, storeItemInfo])

    const onNewButtonClick = React.useCallback((item) => {
        history.push(`/sounds/${storeItemInfo.id}/instruments/new-instrument`)
    }, [history, storeItemInfo])

    type InstrumentDTO = Exclude<SearchInstrumentsQuery["instruments_search"]["instruments"][number], undefined | null>

    const tableRows = instrumentsQuery.data?.instruments_search.instruments.map((item: InstrumentDTO) => {
            return (
                <Tr>
                    <Td>
                        <Checkbox isChecked={selectedItemsIds.has(item.id)}
                                  onChange={(event) => {
                                      onCheckboxChange(item.id, event.target.checked)
                                  }}
                        />
                    </Td>
                    <Td wordBreak={"normal"}>{item.name}</Td>
                    <Td textAlign={"center"} w={"70px"}>
                        <IconButton
                            onClick={() => {
                                onEditButtonClick(item)
                            }}
                            colorScheme="blue"
                            variant={"link"}
                            aria-label="Edit"
                            icon={<FiEdit/>}
                        />
                    </Td>
                </Tr>)
        }
    )

    const table = (
        <>
            <Table variant="simple" mt="2rem">
                <Thead>
                    <Tr>
                        <Th/> {/* Checkbox column*/}
                        <Th>Name</Th>
                        <Th/>
                    </Tr>
                </Thead>
                <Tbody>
                    {tableRows}
                </Tbody>
            </Table>
        </>
    )

    return (
        <Box pl="2rem" ml="1rem" flex="1" minW="0" pt="2rem">
            <HStack mt="2" spacing={2}>
                <Input placeholder="Search" width={300} value={searchInputValue} onChange={(e) => {
                    searchFieldValueDidChange(e.target.value)
                }}/>
                <Spacer/>
                {(selectedItemsIds.size > 0 && !instrumentsQuery.isFetching) &&
                <Button leftIcon={<FiTrash/>} colorScheme="red" variant="outline" onClick={onDeleteButtonClick}>
                    Delete Instruments
                </Button>
                }
                <Button leftIcon={<FiPlus/>} colorScheme="blue" variant="outline" onClick={onNewButtonClick}>
                    Add Instrument
                </Button>
            </HStack>
            {instrumentsQuery.isFetching && (<Center p={10}><Spinner size="lg" /></Center>)}
            {deleteItemsMutation.isLoading && (<Center><Text p="4rem">Deleting instruments...</Text></Center>)}

            {!(instrumentsQuery.isFetching || deleteItemsMutation.isLoading) && (
                (instrumentsQuery.data?.instruments_search.totalCount ?? 0) > 0 ?
                    (
                        <>
                            {table}
                            <Paginator totalItemsCount={totalItemsCount} pageSize={itemsPerPage}
                                       currentPage={currentPage} setCurrentPage={onPageChange}/>
                        </>
                    ) :
                    (
                        <Center><Text p="4rem">No data to display</Text></Center>
                    )
            )
            }
            <DeleteConfirmAlert isOpen={deleteAlertIsOpen}
                                onClose={onDeleteAlertIsClosed}
                                title={"Delete Instruments"}
                                description={"Are you sure you want to delete selected instruments?"}
            />

        </Box>
    )

}
