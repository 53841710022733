import * as React from 'react';
import {
    useElementsAndTotalCountQuery,
    useDeleteElementsMutation
} from "../../../graphql"
import {FiUpload, FiTrash} from "react-icons/fi";
import {useHistory} from 'react-router';
import {DeleteConfirmAlert} from "@staffpad/webapp-common"
import {
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Spacer,
    Input,
    Button,
    Center,
    Checkbox, Spinner, HStack
} from "@chakra-ui/react"
import {Paginator} from "@staffpad/webapp-common";
import _ from "lodash"
import {Breadcrumb} from "@staffpad/webapp-common"

export const ElementsPage = () => {

    const [searchInputValue, setSearchInputValue] = React.useState("")
    const [querySearchValue, setQuerySearchValue] = React.useState("")
    const [itemsPerPage] = React.useState(10)
    const [totalItemsCount, setTotalItemsCount] = React.useState(0)
    const [currentPage, setCurrentPage] = React.useState(0)
    const [selectedElementsIds, setSelectedElementsIds] = React.useState<Set<string>>(new Set())
    const deleteElementsMutation = useDeleteElementsMutation()
    const [deleteAlertIsOpen, setDeleteAlertIsOpen] = React.useState(false)

    const elementsQuery = useElementsAndTotalCountQuery(
        {
            filter: {search: querySearchValue},
            paging: {limit: itemsPerPage, offset: (currentPage) * itemsPerPage}
        },
        {
            staleTime: 1000 * 60 * 3,
            onSettled:() => {
                setSelectedElementsIds(new Set())
            },
            onError:(error) => {
              console.error(`Error while fetching elements: ${error}`)
            },
            onSuccess: (data) => {
                setTotalItemsCount(data.elements_search.elementsCount)
            }
        }
    )

    const onPageChange = (page: number) => {
        setCurrentPage(page)
        console.log(`current page: ${page}`)
    }

    const setQuerySearchValueDebounced = React.useMemo(() => _.debounce((value: string) => {
        setQuerySearchValue(value)
        setCurrentPage(0)
    }, 700), [])

    const searchFieldValueDidChange = (value: string) => {
        setSearchInputValue(value)
        setQuerySearchValueDebounced(value)
    }

    const onCheckboxChange = React.useCallback((elementId: string, isSelected: boolean) => {
        setSelectedElementsIds((currentIds: Set<string>) => {
            const updatedSet = new Set(currentIds)
            if (isSelected) {
                updatedSet.add(elementId)
            } else {
                updatedSet.delete(elementId)
            }
            return updatedSet
        })
    }, [])

    const onDeleteAlertIsClosed = React.useCallback((deleteConfirmed: boolean) => {
        setDeleteAlertIsOpen(false)
        if (deleteConfirmed) {

            deleteElementsMutation.mutate(
                {
                    ids: Array.from(selectedElementsIds)
                },
                {
                    onSuccess: () => {
                        elementsQuery.refetch()
                    }
                }
            )
            setSelectedElementsIds(new Set())
        }
    }, [selectedElementsIds, deleteElementsMutation, elementsQuery])

    const history = useHistory()
    const onUploadButtonClick = () => {
        history.push("/products/elements/upload")
    }

    const onDeleteButtonClick = React.useCallback(() => {
        setDeleteAlertIsOpen(true)
    }, [])


    const tableRows = elementsQuery.data?.elements_search.elements.map((element) =>
        <Tr>
            <Td>
                <Checkbox isChecked={selectedElementsIds.has(element.id)}
                          onChange={(event) => { onCheckboxChange(element.id, event.target.checked) }}
                />
            </Td>
            <Td wordBreak={"normal"} dangerouslySetInnerHTML={{__html: _.replace(element.id, /_/g, "_<wbr/>")}}/>
            <Td wordBreak={"normal"}>{element.title}</Td>
            <Td wordBreak={"normal"}>{element.album}</Td>
            <Td wordBreak={"normal"}>{element.artists.join(",")}</Td>
            <Td wordBreak={"normal"}>{element.genres.join(",")}</Td>
            <Td wordBreak={"normal"}>{element.publisher}</Td>
            <Td wordBreak={"normal"}>{element.type}</Td>
            <Td wordBreak={"normal"}>{element.instrument}</Td>
            <Td wordBreak={"normal"}>{element.keywords.join(", ")}</Td>
            <Td wordBreak={"normal"}>{element.key ?? "All"}</Td>
            <Td isNumeric>{element.bpm ?? "All"}</Td>
            <Td isNumeric>{element.duration && _.round(element.duration, 2)}</Td>
        </Tr>
    )

    const table = (
        <>
            <Table variant="simple" mt="2rem">
                <Thead>
                    <Tr>
                        <Th/> {/* Checkbox column*/}
                        <Th>ID</Th>
                        <Th>Title</Th>
                        <Th>Album</Th>
                        <Th>Artists</Th>
                        <Th>Genres</Th>
                        <Th>Publisher</Th>
                        <Th>Type</Th>
                        <Th>Instrument</Th>
                        <Th>Keywords</Th>
                        <Th>Key</Th>
                        <Th isNumeric>BPM</Th>
                        <Th isNumeric>Duration</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {tableRows}
                </Tbody>
            </Table>
        </>
    )

    return (
        <>
            <Breadcrumb pathItems={[{name: "Elements", path: "/products/elements"}]}/>
            <HStack mt="2" spacing={2}>
                <Box py="4">
                    <Input placeholder="Search" value={searchInputValue} onChange={(e) => {
                        searchFieldValueDidChange(e.target.value)
                    }}/>
                </Box>
                <Spacer/>
                { (selectedElementsIds.size > 0 && !elementsQuery.isFetching) &&
                    <Box p="4">
                        <Button leftIcon={<FiTrash/>} colorScheme="red" variant="outline" onClick={onDeleteButtonClick}>
                            Delete Selected
                        </Button>
                    </Box>
                }
                <Box py="4" >
                    <Button leftIcon={<FiUpload/>} colorScheme="blue" variant="outline" onClick={onUploadButtonClick}>
                        Upload Elements
                    </Button>
                </Box>

            </HStack>

            {elementsQuery.isFetching && (<Center p={10}><Spinner size="lg" /></Center>)}
            {deleteElementsMutation.isLoading && (<Center><Text p="4rem">Deleting items...</Text></Center>)}

            {!(elementsQuery.isFetching || deleteElementsMutation.isLoading) && (
                (elementsQuery.data?.elements_search.elementsCount ?? 0) > 0 ?
                    (
                        <>
                            {table}
                            <Paginator totalItemsCount={totalItemsCount} pageSize={itemsPerPage}
                                       currentPage={currentPage} setCurrentPage={onPageChange}/>
                        </>
                    ) :
                    (
                        <Center><Text p="4rem">No data to display</Text></Center>
                    )
            )
            }
            <DeleteConfirmAlert isOpen={deleteAlertIsOpen}
                                onClose={onDeleteAlertIsClosed}
                                title={"Delete Elements"}
                                description={"Are you sure you want to delete selected elements?"}
            />
        </>
    );
};

