import axios from "axios";
import {getAuthenticationToken} from "../../security/getAuthenticationToken";

export interface ValidationError {
    errorMessage: string
}

export enum ImportStatus {
    created = "created",
    updated = "updated",
    failed = "failed"
}

export interface ElementImportResult {
    fileName: string
    status: ImportStatus
    errors: ValidationError[]
}

export class ElementsFileUploadService {

    private readonly uploadUrl: string

    constructor(uploadUrl: string) {
        this.uploadUrl = uploadUrl
    }

    async uploadElementFiles(files: File[]): Promise<ElementImportResult[]>{
        const formData = new FormData();

        for (const file of files) {
            formData.append(file.name, file)
        }
        const authToken = await getAuthenticationToken()
        const {data: {importResults}, } = await axios.post(this.uploadUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${authToken}`
            }
        })
        return importResults as ElementImportResult[]
    }
}