import * as React from 'react';
import { DeleteConfirmAlert, Paginator} from "@staffpad/webapp-common";
import {
    Box, Button,
    Center,
    Checkbox,
    HStack,
    IconButton,
    Spacer, Spinner,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure
} from "@chakra-ui/react";

import { FiEdit, FiPlus, FiTrash} from "react-icons/fi";
import {useHistory} from "react-router";
import {
    InstrumentDefinitionFieldsFragment,
    InstrumentFieldsFragment,
    useDeleteInstrumentDefinitionsMutation,
    useSearchInstrumentDefinitionsQuery,
} from "../../../../../../graphql";
import {StoreItemInfo} from "../../../SoundInfo";
import {NewDefinitionDialog} from "./NewDefinitionDialog/NewDefinitionDialog";
import {useCallback} from "react";


interface InstrumentDefinitionsPageProps {
    storeItemInfo: StoreItemInfo
    instrument: InstrumentFieldsFragment
}

export const InstrumentDefinitionsPage = (props: InstrumentDefinitionsPageProps) => {
    const {storeItemInfo, instrument} = props

    //const [searchInputValue, setSearchInputValue] = React.useState("")
    //const [_querySearchValue, setQuerySearchValue] = React.useState("")
    const [selectedItemsIds, setSelectedItemsIds] = React.useState<Set<string>>(new Set())
    const [itemsPerPage] = React.useState(10)
    //const [totalItemsCount, setTotalItemsCount] = React.useState(0)
    const [currentPage, setCurrentPage] = React.useState(0)
    const deleteItemsMutation = useDeleteInstrumentDefinitionsMutation()
    const [deleteAlertIsOpen, setDeleteAlertIsOpen] = React.useState(false)

    const definitionsQuery = useSearchInstrumentDefinitionsQuery(
        {
            instrumentId: instrument.id,

            paging: {limit: itemsPerPage, offset: (currentPage) * itemsPerPage}
        },
        {
            staleTime: 1000 * 60 * 3,
            cacheTime: 0,
            refetchOnMount: true,
            onSettled: () => {
                setSelectedItemsIds(new Set())
            },
            onError: (error: any) => {
                console.error(`Error while fetching elements: ${error}`)
            },
            onSuccess: (data: any) => {}
        }
    )

    const totalItemsCount = definitionsQuery.data?.instruments_definitionsSearch.totalCount

    const onPageChange = (page: number) => {
        setCurrentPage(page)
        console.log(`current page: ${page}`)
    }

    // const setQuerySearchValueDebounced = React.useMemo(() => _.debounce((value: string) => {
    //     setQuerySearchValue(value)
    //     setCurrentPage(0)
    // }, 700), [])
    //
    // const searchFieldValueDidChange = (value: string) => {
    //     setSearchInputValue(value)
    //     setQuerySearchValueDebounced(value)
    // }

    const onCheckboxChange = React.useCallback((itemId: string, isSelected: boolean) => {
        setSelectedItemsIds((currentIds: Set<string>) => {
            const updatedSet = new Set(currentIds)
            if (isSelected) {
                updatedSet.add(itemId)
            } else {
                updatedSet.delete(itemId)
            }
            return updatedSet
        })
    }, [])

    const onDeleteAlertIsClosed = React.useCallback((deleteConfirmed: boolean) => {
        setDeleteAlertIsOpen(false)
        if (deleteConfirmed) {

            deleteItemsMutation.mutate(
                {
                    ids: Array.from(selectedItemsIds)
                },
                {
                    onSuccess: () => {
                        definitionsQuery.refetch()
                    }
                }
            )
            setSelectedItemsIds(new Set())
        }
    }, [selectedItemsIds, deleteItemsMutation, definitionsQuery])

    const history = useHistory()

    const onDeleteButtonClick = React.useCallback(() => {
        setDeleteAlertIsOpen(true)
    }, [])

    const onEditButtonClick = React.useCallback((item) => {
        history.push(`/sounds/${storeItemInfo.id}/instruments/${instrument.id}/definitions/${item.id}`)
    }, [history, storeItemInfo, instrument])

    const {
        isOpen: isNewItemDialogOpen,
        onOpen: newItemDialogOpen,
        onClose: newItemDialogClose} = useDisclosure()

    const onNewButtonClick = React.useCallback((item) => {
        newItemDialogOpen()
    }, [newItemDialogOpen])

    const onBuildCreated = useCallback((_definitionId: string) => {
        newItemDialogClose()
        definitionsQuery.remove()
        definitionsQuery.refetch()
    }, [newItemDialogClose, definitionsQuery])


    const tableRows = definitionsQuery.data?.instruments_definitionsSearch.definitions.map((item: InstrumentDefinitionFieldsFragment) => {
            return (
                <Tr>
                    <Td>
                        <Checkbox isChecked={selectedItemsIds.has(item.id)}
                                  onChange={(event) => {
                                      onCheckboxChange(item.id, event.target.checked)
                                  }}
                        />
                    </Td>
                    <Td wordBreak={"normal"}>{item.format}</Td>
                    <Td wordBreak={"normal"}>{item.revision}</Td>
                    <Td wordBreak={"normal"}>{new Date(item.createdDate).toLocaleString()}</Td>
                    <Td textAlign={"center"} w={"70px"}>
                        <IconButton
                            onClick={() => {
                                onEditButtonClick(item)
                            }}
                            colorScheme="blue"
                            variant={"link"}
                            aria-label="Edit"
                            icon={<FiEdit/>}
                        />
                    </Td>
                </Tr>)
        }
    )

    const table = (
        <>
            <Table variant="simple" mt="2rem">
                <Thead>
                    <Tr>
                        <Th/> {/* Checkbox column*/}
                        <Th>Format</Th>
                        <Th>Revision</Th>
                        <Th>Create Date</Th>
                        <Th/>
                    </Tr>
                </Thead>
                <Tbody>
                    {tableRows}
                </Tbody>
            </Table>
        </>
    )

    return (
        <>
        <Box pl="2rem" ml="1rem" flex="1" minW="0" pt="2rem">
            <HStack mt="2" spacing={2}>
                {/*<Input placeholder="Search" width={300} value={searchInputValue} onChange={(e) => {*/}
                {/*    searchFieldValueDidChange(e.target.value)*/}
                {/*}}/>*/}
                <Spacer/>
                {(selectedItemsIds.size > 0 && !definitionsQuery.isFetching) &&
                <Button leftIcon={<FiTrash/>} colorScheme="red" variant="outline" onClick={onDeleteButtonClick}>
                    Delete Definitions
                </Button>
                }
                <Button leftIcon={<FiPlus/>} colorScheme="blue" variant="outline" onClick={onNewButtonClick}>
                    Add Definition
                </Button>
            </HStack>
            {definitionsQuery.isFetching && (<Center p={10}><Spinner size="lg" /></Center>)}
            {deleteItemsMutation.isLoading && (<Center><Text p="4rem">Deleting definitions...</Text></Center>)}

            {!(definitionsQuery.isFetching || deleteItemsMutation.isLoading) && (
                (definitionsQuery.data?.instruments_definitionsSearch.totalCount ?? 0) > 0 ?
                    (
                        <>
                            {table}
                            <Paginator totalItemsCount={totalItemsCount} pageSize={itemsPerPage}
                                       currentPage={currentPage} setCurrentPage={onPageChange}/>
                        </>
                    ) :
                    (
                        <Center><Text p="4rem">No data to display</Text></Center>
                    )
            )
            }
            <DeleteConfirmAlert isOpen={deleteAlertIsOpen}
                                onClose={onDeleteAlertIsClosed}
                                title={"Delete Definitions"}
                                description={"Are you sure you want to delete selected instrument definitions?"}
            />

        </Box>
            <NewDefinitionDialog instrumentId={instrument.id} isOpen={isNewItemDialogOpen}
                               onClose={newItemDialogClose}
                               onSave={onBuildCreated}/>
        </>
    )

}
