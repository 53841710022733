import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetchData } from './fetcher';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};









export type AdditionalEntityFields = {
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type BpmRange = {
  from: Scalars['Float'];
  to: Scalars['Float'];
};

export type Element = {
  __typename?: 'Element';
  id: Scalars['String'];
  title: Scalars['String'];
  album?: Maybe<Scalars['String']>;
  artists: Array<Scalars['String']>;
  genres: Array<Scalars['String']>;
  publisher?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
  bpm?: Maybe<Scalars['Float']>;
  key?: Maybe<Scalars['String']>;
  instrument?: Maybe<Scalars['String']>;
  type: ElementType;
  keywords: Array<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  updatedDate: Scalars['String'];
};

export type ElementAlbum = {
  __typename?: 'ElementAlbum';
  id: Scalars['String'];
  title: Scalars['String'];
  elementsCount: Scalars['Int'];
};

export type ElementAlbumFilterInput = {
  search?: Maybe<Scalars['String']>;
};

export type ElementFilterInput = {
  search?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['String']>>;
  album?: Maybe<Scalars['String']>;
  artists?: Maybe<Array<Scalars['String']>>;
  genres?: Maybe<Array<Scalars['String']>>;
  publisher?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  bpmExact?: Maybe<Scalars['Float']>;
  bpmRange?: Maybe<BpmRange>;
  instrument?: Maybe<Scalars['String']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
};

export type ElementGenre = {
  __typename?: 'ElementGenre';
  id: Scalars['String'];
  title: Scalars['String'];
  elementsCount: Scalars['Int'];
};

export type ElementGenreFilterInput = {
  search?: Maybe<Scalars['String']>;
};

export type ElementInstrument = {
  __typename?: 'ElementInstrument';
  id: Scalars['String'];
  title: Scalars['String'];
  elementsCount: Scalars['Int'];
};

export type ElementInstrumentFilterInput = {
  search?: Maybe<Scalars['String']>;
};

export type ElementKeyword = {
  __typename?: 'ElementKeyword';
  id: Scalars['String'];
  title: Scalars['String'];
  elementsCount: Scalars['Int'];
};

export type ElementKeywordFilterInput = {
  search?: Maybe<Scalars['String']>;
};

export enum ElementOrderByField {
  Title = 'title',
  Artist = 'artist',
  Category = 'category',
  Duration = 'duration',
  Tempo = 'tempo',
  Key = 'key',
  UpdatedDate = 'updatedDate'
}

export type ElementOrderByInput = {
  field: ElementOrderByField;
  direction: OrderDirection;
};

export enum ElementSearchOrderByField {
  Title = 'title',
  UpdatedDate = 'updatedDate'
}

export type ElementSearchOrderByInput = {
  field: ElementSearchOrderByField;
  direction: OrderDirection;
};

export type ElementSearchResult = {
  __typename?: 'ElementSearchResult';
  elements: Array<Element>;
  elementsCount: Scalars['Int'];
  keywords: Array<ElementKeyword>;
};

export enum ElementType {
  OneShot = 'one_shot',
  Loop = 'loop'
}

export type Instrument = {
  __typename?: 'Instrument';
  id: Scalars['String'];
  intId: Scalars['Int'];
  storeItemId: Scalars['String'];
  directoryPath: Scalars['String'];
  name: Scalars['String'];
  category: Scalars['String'];
  abbreviation: Scalars['String'];
  createdDate: Scalars['String'];
  updatedDate: Scalars['String'];
  l10n?: Maybe<Array<Instrument_L10n>>;
  definitions: Array<InstrumentDefinition>;
  storeItemPurchaseId?: Maybe<Scalars['String']>;
};


export type InstrumentDefinitionsArgs = {
  onlyLatestRevisions?: Maybe<Scalars['Boolean']>;
};

export type InstrumentDefinition = {
  __typename?: 'InstrumentDefinition';
  id: Scalars['String'];
  instrumentId: Scalars['String'];
  samplesId?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  revision: Scalars['Int'];
  fileName: Scalars['String'];
  createdDate: Scalars['String'];
  samplesFileName?: Maybe<Scalars['String']>;
};

export type InstrumentDefinitionInput = {
  instrumentId: Scalars['String'];
  samplesId?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  fileName: Scalars['String'];
};

export type InstrumentDefinitionUpdateInput = {
  id: Scalars['String'];
  samplesId?: Maybe<Scalars['String']>;
};

export type InstrumentDefinitionsSearchResult = {
  __typename?: 'InstrumentDefinitionsSearchResult';
  definitions: Array<InstrumentDefinition>;
  totalCount: Scalars['Int'];
};

export type InstrumentInput = {
  id: Scalars['Int'];
  storeItemId: Scalars['String'];
  name: Scalars['String'];
  category: Scalars['String'];
  abbreviation: Scalars['String'];
  directoryPath: Scalars['String'];
  l10n?: Maybe<Array<InstrumentInput_L10n>>;
};

export type InstrumentInput_L10n = {
  l10n_code: Scalars['String'];
  l10n_locale: Scalars['String'];
  l10n_osxCode: Scalars['String'];
  l10n_osxLocale: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type InstrumentSamples = {
  __typename?: 'InstrumentSamples';
  id: Scalars['String'];
  instrumentId: Scalars['String'];
  fileName: Scalars['String'];
  createdDate: Scalars['String'];
};

export type InstrumentSamplesInput = {
  instrumentId: Scalars['String'];
  fileName: Scalars['String'];
  createdDate: Scalars['String'];
};

export type Instrument_L10n = L10n & {
  __typename?: 'Instrument_L10n';
  l10n_code: Scalars['String'];
  l10n_locale: Scalars['String'];
  l10n_osxCode: Scalars['String'];
  l10n_osxLocale: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
};

export type InstrumentsFilterInput = {
  name?: Maybe<Scalars['String']>;
  storeItemId?: Maybe<Scalars['String']>;
};

export type InstrumentsSearchResult = {
  __typename?: 'InstrumentsSearchResult';
  instruments: Array<Instrument>;
  totalCount: Scalars['Int'];
};

export type L10n = {
  l10n_code: Scalars['String'];
  l10n_locale: Scalars['String'];
  l10n_osxCode: Scalars['String'];
  l10n_osxLocale: Scalars['String'];
};

export enum L10nEntity {
  StoreItem = 'storeItem',
  StoreSection = 'storeSection',
  Instrument = 'instrument'
}

export type Mutation = {
  __typename?: 'Mutation';
  elements_deleteElement?: Maybe<Scalars['String']>;
  elements_deleteElements: Array<Scalars['String']>;
  /** Repopulate the recent_elements db container with up-to-date elements. Returns number of added elements. */
  elements_updateRecent?: Maybe<Scalars['Int']>;
  elements_run_indexer?: Maybe<Scalars['Boolean']>;
  instruments_updateOrCreate: Instrument;
  instruments_createDefinition: InstrumentDefinition;
  instruments_updateDefinition: InstrumentDefinition;
  instruments_deleteDefinitions: Array<Scalars['String']>;
  instruments_createSamples: InstrumentSamples;
  instruments_delete: Array<Scalars['String']>;
  instruments_import?: Maybe<Scalars['Int']>;
  instruments_generateCacheFile?: Maybe<Scalars['String']>;
  instruments_getNextId?: Maybe<Scalars['Int']>;
  instruments_setNextId?: Maybe<Scalars['Int']>;
  store_updateOrCreateStoreSection: StoreSection;
  store_deleteSections: Array<Scalars['String']>;
  store_updateOrCreateFeaturedItem: StoreItem;
  store_updateOrCreateRegularItem: StoreItem;
  store_deleteItem?: Maybe<Scalars['String']>;
  store_deleteItems: Array<Scalars['String']>;
  store_uploadLocalizableStringsToCrowdin?: Maybe<Scalars['Int']>;
  store_syncTranslationsWithCrowdin?: Maybe<Scalars['Int']>;
  testItems_updateOrCreate: TestItem;
  testItems_delete: Array<Scalars['String']>;
  addTestPerformanceItem?: Maybe<TestPerformanceItem>;
  deleteTestPerformanceItem: Array<Scalars['String']>;
  deleteAllTestPerformanceItems?: Maybe<Scalars['Boolean']>;
  bulkCreateItems?: Maybe<Scalars['Boolean']>;
};


export type MutationElements_DeleteElementArgs = {
  id: Scalars['String'];
};


export type MutationElements_DeleteElementsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationInstruments_UpdateOrCreateArgs = {
  instrument: InstrumentInput;
};


export type MutationInstruments_CreateDefinitionArgs = {
  definition: InstrumentDefinitionInput;
};


export type MutationInstruments_UpdateDefinitionArgs = {
  definition: InstrumentDefinitionUpdateInput;
};


export type MutationInstruments_DeleteDefinitionsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationInstruments_CreateSamplesArgs = {
  samples: InstrumentSamplesInput;
};


export type MutationInstruments_DeleteArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationInstruments_SetNextIdArgs = {
  value: Scalars['Int'];
};


export type MutationStore_UpdateOrCreateStoreSectionArgs = {
  section: StoreSectionInput;
};


export type MutationStore_DeleteSectionsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationStore_UpdateOrCreateFeaturedItemArgs = {
  item: StoreItemFeaturedInput;
};


export type MutationStore_UpdateOrCreateRegularItemArgs = {
  item: StoreItemRegularInput;
};


export type MutationStore_DeleteItemArgs = {
  id: Scalars['String'];
};


export type MutationStore_DeleteItemsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationTestItems_UpdateOrCreateArgs = {
  item: TestItemInput;
};


export type MutationTestItems_DeleteArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationAddTestPerformanceItemArgs = {
  item: TestPerformanceItemInput;
};


export type MutationDeleteTestPerformanceItemArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationBulkCreateItemsArgs = {
  count: Scalars['Int'];
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type PagingInput = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  elements_albums: Array<ElementAlbum>;
  elements_genres: Array<ElementGenre>;
  elements_newField: Scalars['String'];
  elements_instruments: Array<ElementInstrument>;
  elements_keywords: Array<ElementKeyword>;
  elements_search: ElementSearchResult;
  elements: Array<Element>;
  elementsCount: Scalars['Int'];
  elements_recent: Array<Element>;
  elements_recentCount?: Maybe<Scalars['Int']>;
  instruments_search: InstrumentsSearchResult;
  instrument?: Maybe<Instrument>;
  instruments_definitionsSearch: InstrumentDefinitionsSearchResult;
  instruments_getNextDefinitionRevision: Scalars['Int'];
  instruments_definition: InstrumentDefinition;
  store_sectionsSearch: StoreSectionsSearchResult;
  store: Store;
  store_itemsSearch: StoreItemsSearchResult;
  store_item?: Maybe<StoreItem>;
  store_items: Array<StoreItem>;
  store_section?: Maybe<StoreSection>;
  store_supportedLanguages: Array<StoreLanguage>;
  store_crowdinFileId?: Maybe<Scalars['Int']>;
  testItems: Array<TestItem>;
  /** Query for performance DB testing. Finds items by string and/or number and measures query time */
  testPerformance: Array<TestPerformanceItem>;
  testPerformanceItemsCount: Scalars['Int'];
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryElements_SearchArgs = {
  filter?: Maybe<ElementFilterInput>;
  orderBy?: Maybe<ElementSearchOrderByInput>;
  paging?: Maybe<PagingInput>;
};


export type QueryElementsArgs = {
  filter?: Maybe<ElementFilterInput>;
  orderBy?: Maybe<ElementOrderByInput>;
  paging?: Maybe<PagingInput>;
};


export type QueryElementsCountArgs = {
  filter?: Maybe<ElementFilterInput>;
};


export type QueryElements_RecentArgs = {
  paging?: Maybe<PagingInput>;
};


export type QueryInstruments_SearchArgs = {
  l10n_locale?: Maybe<Scalars['String']>;
  l10n_osxLocale?: Maybe<Scalars['String']>;
  filter?: Maybe<InstrumentsFilterInput>;
  paging?: Maybe<PagingInput>;
};


export type QueryInstrumentArgs = {
  id: Scalars['String'];
};


export type QueryInstruments_DefinitionsSearchArgs = {
  instrumentId: Scalars['String'];
  paging?: Maybe<PagingInput>;
};


export type QueryInstruments_GetNextDefinitionRevisionArgs = {
  instrumentId: Scalars['String'];
  format: Scalars['String'];
};


export type QueryInstruments_DefinitionArgs = {
  id: Scalars['String'];
};


export type QueryStore_SectionsSearchArgs = {
  filter?: Maybe<StoreSectionFilterInput>;
  paging?: Maybe<PagingInput>;
};


export type QueryStore_ItemsSearchArgs = {
  filter?: Maybe<StoreItemsFilterInput>;
  orderBy?: Maybe<StoreItemsSearchOrderByInput>;
  paging?: Maybe<PagingInput>;
};


export type QueryStore_ItemArgs = {
  id: Scalars['String'];
};


export type QueryStore_ItemsArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryStore_SectionArgs = {
  id: Scalars['String'];
};


export type QueryStore_CrowdinFileIdArgs = {
  l10nEntity: L10nEntity;
};


export type QueryTestItemsArgs = {
  filter?: Maybe<TestItemFilterInput>;
  paging?: Maybe<PagingInput>;
};


export type QueryTestPerformanceArgs = {
  filter?: Maybe<TestPerformanceItemFilterInput>;
  paging?: Maybe<PagingInput>;
};


export type QueryTestPerformanceItemsCountArgs = {
  filter?: Maybe<TestPerformanceItemInput>;
};

export type Store = {
  __typename?: 'Store';
  sections: Array<StoreSection>;
};


export type StoreSectionsArgs = {
  l10n_locale?: Maybe<Scalars['String']>;
  l10n_osxLocale?: Maybe<Scalars['String']>;
};

export type StoreItem = {
  __typename?: 'StoreItem';
  id: Scalars['String'];
  folderName: Array<Scalars['String']>;
  PurchaseId: Array<Scalars['String']>;
  imageURL: Scalars['String'];
  accent: Scalars['String'];
  type: StoreItemDetailsType;
  instrumentIds: Array<Scalars['String']>;
  instruments: Array<Instrument>;
  title?: Maybe<Scalars['String']>;
};

export type StoreItemDemo = {
  __typename?: 'StoreItemDemo';
  title: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
  l10n?: Maybe<Array<StoreItemDemo_L10n>>;
};

export type StoreItemDemoInput = {
  title: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
  l10n?: Maybe<Array<StoreItemDemoInput_L10n>>;
};

export type StoreItemDemoInput_L10n = {
  l10n_code: Scalars['String'];
  l10n_locale: Scalars['String'];
  l10n_osxCode: Scalars['String'];
  l10n_osxLocale: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type StoreItemDemo_L10n = L10n & {
  __typename?: 'StoreItemDemo_l10n';
  l10n_code: Scalars['String'];
  l10n_locale: Scalars['String'];
  l10n_osxCode: Scalars['String'];
  l10n_osxLocale: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type StoreItemDetailsFeatured = {
  __typename?: 'StoreItemDetailsFeatured';
  title: Scalars['String'];
  logoURL: Scalars['String'];
  featuredDemos: Array<StoreItemDemo>;
  demos: Array<StoreItemDemo>;
  introduction: Scalars['String'];
  features: Array<Scalars['String']>;
  instruments: Array<Scalars['String']>;
  genre: Scalars['String'];
  genreIconURL: Scalars['String'];
  released: Scalars['Int'];
  size: Scalars['Float'];
  sizeUnits: Scalars['String'];
  partner: Scalars['String'];
  website: Scalars['String'];
  l10n?: Maybe<Array<StoreItemDetailsFeatured_L10n>>;
};

export type StoreItemDetailsFeaturedInput = {
  title: Scalars['String'];
  logoURL: Scalars['String'];
  featuredDemos: Array<StoreItemDemoInput>;
  demos: Array<StoreItemDemoInput>;
  introduction: Scalars['String'];
  features: Array<Scalars['String']>;
  instruments: Array<Scalars['String']>;
  genre: Scalars['String'];
  genreIconURL: Scalars['String'];
  released: Scalars['Int'];
  size: Scalars['Float'];
  sizeUnits: Scalars['String'];
  partner: Scalars['String'];
  website: Scalars['String'];
  l10n?: Maybe<Array<StoreItemDetailsFeaturedInput_L10n>>;
};

export type StoreItemDetailsFeaturedInput_L10n = {
  l10n_code: Scalars['String'];
  l10n_locale: Scalars['String'];
  l10n_osxCode: Scalars['String'];
  l10n_osxLocale: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Scalars['String']>>;
  instruments?: Maybe<Array<Scalars['String']>>;
  genre?: Maybe<Scalars['String']>;
};

export type StoreItemDetailsFeatured_L10n = L10n & {
  __typename?: 'StoreItemDetailsFeatured_l10n';
  l10n_code: Scalars['String'];
  l10n_locale: Scalars['String'];
  l10n_osxCode: Scalars['String'];
  l10n_osxLocale: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Scalars['String']>>;
  instruments?: Maybe<Array<Scalars['String']>>;
  genre?: Maybe<Scalars['String']>;
};

export type StoreItemDetailsRegular = {
  __typename?: 'StoreItemDetailsRegular';
  title: Scalars['String'];
  creator: Scalars['String'];
  subtitle: Scalars['String'];
  description: Scalars['String'];
  instrument: Scalars['String'];
  demos: Array<StoreItemDemo>;
  l10n?: Maybe<Array<StoreItemDetailsRegular_L10n>>;
};

export type StoreItemDetailsRegularInput = {
  title: Scalars['String'];
  creator: Scalars['String'];
  subtitle: Scalars['String'];
  description: Scalars['String'];
  instrument: Scalars['String'];
  demos: Array<StoreItemDemoInput>;
  l10n?: Maybe<Array<StoreItemDetailsRegularInput_L10n>>;
};

export type StoreItemDetailsRegularInput_L10n = {
  l10n_code: Scalars['String'];
  l10n_locale: Scalars['String'];
  l10n_osxCode: Scalars['String'];
  l10n_osxLocale: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instrument?: Maybe<Scalars['String']>;
};

export type StoreItemDetailsRegular_L10n = L10n & {
  __typename?: 'StoreItemDetailsRegular_l10n';
  l10n_code: Scalars['String'];
  l10n_locale: Scalars['String'];
  l10n_osxCode: Scalars['String'];
  l10n_osxLocale: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instrument?: Maybe<Scalars['String']>;
};

export type StoreItemDetailsType = StoreItemDetailsTypeFeatured | StoreItemDetailsTypeRegular;

export type StoreItemDetailsTypeFeatured = {
  __typename?: 'StoreItemDetailsTypeFeatured';
  featured: StoreItemDetailsFeatured;
};

export type StoreItemDetailsTypeRegular = {
  __typename?: 'StoreItemDetailsTypeRegular';
  regular: StoreItemDetailsRegular;
};

export type StoreItemFeaturedInput = {
  id: Scalars['String'];
  folderName: Array<Scalars['String']>;
  PurchaseId: Array<Scalars['String']>;
  instrumentIds: Array<Scalars['String']>;
  imageURL: Scalars['String'];
  accent: Scalars['String'];
  featuredDetails: StoreItemDetailsFeaturedInput;
};

export type StoreItemRegularInput = {
  id: Scalars['String'];
  folderName: Array<Scalars['String']>;
  PurchaseId: Array<Scalars['String']>;
  instrumentIds: Array<Scalars['String']>;
  imageURL: Scalars['String'];
  accent: Scalars['String'];
  regularDetails: StoreItemDetailsRegularInput;
};

export type StoreItemsFilterInput = {
  search?: Maybe<Scalars['String']>;
  purchaseId?: Maybe<Scalars['String']>;
};

export enum StoreItemsSearchOrderByField {
  Title = 'title',
  UpdatedDate = 'updatedDate'
}

export type StoreItemsSearchOrderByInput = {
  field: StoreItemsSearchOrderByField;
  direction: OrderDirection;
};

export type StoreItemsSearchResult = {
  __typename?: 'StoreItemsSearchResult';
  items: Array<StoreItem>;
  totalCount: Scalars['Int'];
};

export type StoreLanguage = {
  __typename?: 'StoreLanguage';
  code: Scalars['String'];
  name: Scalars['String'];
  locale: Scalars['String'];
  osxCode: Scalars['String'];
  osxLocale: Scalars['String'];
  crowdinEditorCode: Scalars['String'];
};

export type StoreSection = {
  __typename?: 'StoreSection';
  id: Scalars['String'];
  title: Scalars['String'];
  areItemsShuffled: Scalars['Boolean'];
  itemIds: Array<Scalars['String']>;
  l10n?: Maybe<Array<StoreSectionL10n>>;
  items: Array<StoreItem>;
};


export type StoreSectionItemsArgs = {
  l10n_locale?: Maybe<Scalars['String']>;
  l10n_osxLocale?: Maybe<Scalars['String']>;
};

export type StoreSectionFilterInput = {
  search?: Maybe<Scalars['String']>;
};

export type StoreSectionInput = {
  id: Scalars['String'];
  title: Scalars['String'];
  areItemsShuffled: Scalars['Boolean'];
  itemIds: Array<Scalars['String']>;
  l10n?: Maybe<Array<StoreSectionL10nInput>>;
};

export type StoreSectionL10n = L10n & {
  __typename?: 'StoreSectionL10n';
  l10n_code: Scalars['String'];
  l10n_locale: Scalars['String'];
  l10n_osxCode: Scalars['String'];
  l10n_osxLocale: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type StoreSectionL10nInput = {
  l10n_code: Scalars['String'];
  l10n_locale: Scalars['String'];
  l10n_osxCode: Scalars['String'];
  l10n_osxLocale: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type StoreSectionsSearchResult = {
  __typename?: 'StoreSectionsSearchResult';
  items: Array<StoreSection>;
  totalCount: Scalars['Int'];
};

export type TestExtraOptions = {
  __typename?: 'TestExtraOptions';
  stringOption?: Maybe<Scalars['String']>;
  booleanOption?: Maybe<Scalars['Boolean']>;
  intOption?: Maybe<Scalars['Int']>;
  floatOption?: Maybe<Scalars['Float']>;
};

export type TestExtraOptionsInput = {
  stringOption?: Maybe<Scalars['String']>;
  booleanOption?: Maybe<Scalars['Boolean']>;
  intOption?: Maybe<Scalars['Int']>;
  floatOption?: Maybe<Scalars['Float']>;
};

export type TestItem = {
  __typename?: 'TestItem';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  intField?: Maybe<Scalars['Int']>;
  extraOptions?: Maybe<TestExtraOptions>;
};

export type TestItemFilterInput = {
  name?: Maybe<Scalars['String']>;
};

export type TestItemInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  intField?: Maybe<Scalars['Int']>;
  extraOptions?: Maybe<TestExtraOptionsInput>;
};

export type TestPerformanceItem = {
  __typename?: 'TestPerformanceItem';
  id: Scalars['String'];
  intField: Scalars['Int'];
  stringField?: Maybe<Scalars['String']>;
  queryTime?: Maybe<Scalars['Int']>;
};

export type TestPerformanceItemFilterInput = {
  int?: Maybe<Scalars['Int']>;
  str?: Maybe<Scalars['String']>;
};

export type TestPerformanceItemInput = {
  id: Scalars['String'];
  intField: Scalars['Int'];
  stringField?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CrowdinFileIdQueryVariables = Exact<{
  entity: L10nEntity;
}>;


export type CrowdinFileIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'store_crowdinFileId'>
);

export type ElementsAndTotalCountQueryVariables = Exact<{
  filter?: Maybe<ElementFilterInput>;
  orderBy?: Maybe<ElementSearchOrderByInput>;
  paging?: Maybe<PagingInput>;
}>;


export type ElementsAndTotalCountQuery = (
  { __typename?: 'Query' }
  & { elements_search: (
    { __typename?: 'ElementSearchResult' }
    & Pick<ElementSearchResult, 'elementsCount'>
    & { elements: Array<(
      { __typename?: 'Element' }
      & Pick<Element, 'id' | 'title' | 'album' | 'artists' | 'genres' | 'publisher' | 'type' | 'instrument' | 'keywords' | 'bpm' | 'duration' | 'key'>
    )> }
  ) }
);

export type DeleteElementsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteElementsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'elements_deleteElements'>
);

export type InstrumentFieldsFragment = (
  { __typename?: 'Instrument' }
  & Pick<Instrument, 'id' | 'intId' | 'storeItemId' | 'directoryPath' | 'name' | 'category' | 'updatedDate' | 'abbreviation'>
);

export type SearchInstrumentsQueryVariables = Exact<{
  filter?: Maybe<InstrumentsFilterInput>;
  paging?: Maybe<PagingInput>;
}>;


export type SearchInstrumentsQuery = (
  { __typename?: 'Query' }
  & { instruments_search: (
    { __typename?: 'InstrumentsSearchResult' }
    & Pick<InstrumentsSearchResult, 'totalCount'>
    & { instruments: Array<(
      { __typename?: 'Instrument' }
      & InstrumentFieldsFragment
    )> }
  ) }
);

export type InstrumentDefinitionFieldsFragment = (
  { __typename?: 'InstrumentDefinition' }
  & Pick<InstrumentDefinition, 'id' | 'instrumentId' | 'samplesId' | 'format' | 'revision' | 'fileName' | 'createdDate' | 'samplesFileName'>
);

export type SearchInstrumentDefinitionsQueryVariables = Exact<{
  instrumentId: Scalars['String'];
  paging?: Maybe<PagingInput>;
}>;


export type SearchInstrumentDefinitionsQuery = (
  { __typename?: 'Query' }
  & { instruments_definitionsSearch: (
    { __typename?: 'InstrumentDefinitionsSearchResult' }
    & Pick<InstrumentDefinitionsSearchResult, 'totalCount'>
    & { definitions: Array<(
      { __typename?: 'InstrumentDefinition' }
      & InstrumentDefinitionFieldsFragment
    )> }
  ) }
);

export type InstrumentByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type InstrumentByIdQuery = (
  { __typename?: 'Query' }
  & { instrument?: Maybe<(
    { __typename?: 'Instrument' }
    & InstrumentFieldsFragment
  )> }
);

export type InstrumentDefinitionByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type InstrumentDefinitionByIdQuery = (
  { __typename?: 'Query' }
  & { instruments_definition: (
    { __typename?: 'InstrumentDefinition' }
    & InstrumentDefinitionFieldsFragment
  ) }
);

export type NextInstrumentDefinitionRevisionQueryVariables = Exact<{
  instrumentId: Scalars['String'];
  format: Scalars['String'];
}>;


export type NextInstrumentDefinitionRevisionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'instruments_getNextDefinitionRevision'>
);

export type UpdateOrCreateInstrumentMutationVariables = Exact<{
  instrument: InstrumentInput;
}>;


export type UpdateOrCreateInstrumentMutation = (
  { __typename?: 'Mutation' }
  & { instruments_updateOrCreate: (
    { __typename?: 'Instrument' }
    & Pick<Instrument, 'id'>
  ) }
);

export type UpdateInstrumentDefinitionMutationVariables = Exact<{
  definition: InstrumentDefinitionUpdateInput;
}>;


export type UpdateInstrumentDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { instruments_updateDefinition: (
    { __typename?: 'InstrumentDefinition' }
    & InstrumentDefinitionFieldsFragment
  ) }
);

export type DeleteInstrumentsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteInstrumentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'instruments_delete'>
);

export type CreateInstrumentDefinitionMutationVariables = Exact<{
  definition: InstrumentDefinitionInput;
}>;


export type CreateInstrumentDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { instruments_createDefinition: (
    { __typename?: 'InstrumentDefinition' }
    & Pick<InstrumentDefinition, 'id'>
  ) }
);

export type DeleteInstrumentDefinitionsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteInstrumentDefinitionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'instruments_deleteDefinitions'>
);

export type StoreItemDemoFieldsFragment = (
  { __typename?: 'StoreItemDemo' }
  & Pick<StoreItemDemo, 'title' | 'type' | 'url'>
  & { l10n?: Maybe<Array<(
    { __typename?: 'StoreItemDemo_l10n' }
    & Pick<StoreItemDemo_L10n, 'l10n_code' | 'l10n_locale' | 'l10n_osxCode' | 'l10n_osxLocale' | 'title'>
  )>> }
);

export type StoreItemFragmentFragment = (
  { __typename?: 'StoreItem' }
  & Pick<StoreItem, 'id' | 'PurchaseId' | 'folderName' | 'instrumentIds' | 'imageURL' | 'accent'>
  & { type: (
    { __typename?: 'StoreItemDetailsTypeFeatured' }
    & { featured: (
      { __typename?: 'StoreItemDetailsFeatured' }
      & Pick<StoreItemDetailsFeatured, 'title' | 'logoURL' | 'introduction' | 'features' | 'instruments' | 'genre' | 'genreIconURL' | 'released' | 'size' | 'sizeUnits' | 'partner' | 'website'>
      & { featuredDemos: Array<(
        { __typename?: 'StoreItemDemo' }
        & StoreItemDemoFieldsFragment
      )>, demos: Array<(
        { __typename?: 'StoreItemDemo' }
        & StoreItemDemoFieldsFragment
      )>, l10n?: Maybe<Array<(
        { __typename?: 'StoreItemDetailsFeatured_l10n' }
        & Pick<StoreItemDetailsFeatured_L10n, 'l10n_code' | 'l10n_locale' | 'l10n_osxCode' | 'l10n_osxLocale' | 'title' | 'introduction' | 'features' | 'instruments' | 'genre'>
      )>> }
    ) }
  ) | (
    { __typename?: 'StoreItemDetailsTypeRegular' }
    & { regular: (
      { __typename?: 'StoreItemDetailsRegular' }
      & Pick<StoreItemDetailsRegular, 'title' | 'subtitle' | 'description' | 'instrument' | 'creator'>
      & { demos: Array<(
        { __typename?: 'StoreItemDemo' }
        & StoreItemDemoFieldsFragment
      )>, l10n?: Maybe<Array<(
        { __typename?: 'StoreItemDetailsRegular_l10n' }
        & Pick<StoreItemDetailsRegular_L10n, 'l10n_code' | 'l10n_locale' | 'l10n_osxCode' | 'l10n_osxLocale' | 'title' | 'subtitle' | 'description' | 'instrument'>
      )>> }
    ) }
  ) }
);

export type StoreItemByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type StoreItemByIdQuery = (
  { __typename?: 'Query' }
  & { store_item?: Maybe<(
    { __typename?: 'StoreItem' }
    & StoreItemFragmentFragment
  )> }
);

export type SearchStoreItemsIdTitleQueryVariables = Exact<{
  filter?: Maybe<StoreItemsFilterInput>;
  orderBy?: Maybe<StoreItemsSearchOrderByInput>;
  paging?: Maybe<PagingInput>;
}>;


export type SearchStoreItemsIdTitleQuery = (
  { __typename?: 'Query' }
  & { store_itemsSearch: (
    { __typename?: 'StoreItemsSearchResult' }
    & Pick<StoreItemsSearchResult, 'totalCount'>
    & { items: Array<(
      { __typename?: 'StoreItem' }
      & Pick<StoreItem, 'id' | 'title'>
    )> }
  ) }
);

export type StoreItemTitlesByIdsQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type StoreItemTitlesByIdsQuery = (
  { __typename?: 'Query' }
  & { store_items: Array<(
    { __typename?: 'StoreItem' }
    & Pick<StoreItem, 'id' | 'title'>
  )> }
);

export type SearchStoreItemsQueryVariables = Exact<{
  filter?: Maybe<StoreItemsFilterInput>;
  orderBy?: Maybe<StoreItemsSearchOrderByInput>;
  paging?: Maybe<PagingInput>;
}>;


export type SearchStoreItemsQuery = (
  { __typename?: 'Query' }
  & { store_itemsSearch: (
    { __typename?: 'StoreItemsSearchResult' }
    & Pick<StoreItemsSearchResult, 'totalCount'>
    & { items: Array<(
      { __typename?: 'StoreItem' }
      & StoreItemFragmentFragment
    )> }
  ) }
);

export type DeleteStoreItemsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteStoreItemsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'store_deleteItems'>
);

export type UpdateOrCreateFeaturedItemMutationVariables = Exact<{
  item: StoreItemFeaturedInput;
}>;


export type UpdateOrCreateFeaturedItemMutation = (
  { __typename?: 'Mutation' }
  & { store_updateOrCreateFeaturedItem: (
    { __typename?: 'StoreItem' }
    & Pick<StoreItem, 'id'>
  ) }
);

export type UpdateOrCreateRegularItemMutationVariables = Exact<{
  item: StoreItemRegularInput;
}>;


export type UpdateOrCreateRegularItemMutation = (
  { __typename?: 'Mutation' }
  & { store_updateOrCreateRegularItem: (
    { __typename?: 'StoreItem' }
    & Pick<StoreItem, 'id'>
  ) }
);

export type SyncStoreTranslationsMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncStoreTranslationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'store_syncTranslationsWithCrowdin'>
);

export type StoreLanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type StoreLanguagesQuery = (
  { __typename?: 'Query' }
  & { store_supportedLanguages: Array<(
    { __typename?: 'StoreLanguage' }
    & Pick<StoreLanguage, 'code' | 'name' | 'locale' | 'osxCode' | 'osxLocale' | 'crowdinEditorCode'>
  )> }
);

export type SearchStoreSectionsQueryVariables = Exact<{
  filter?: Maybe<StoreSectionFilterInput>;
  paging?: Maybe<PagingInput>;
}>;


export type SearchStoreSectionsQuery = (
  { __typename?: 'Query' }
  & { store_sectionsSearch: (
    { __typename?: 'StoreSectionsSearchResult' }
    & Pick<StoreSectionsSearchResult, 'totalCount'>
    & { items: Array<(
      { __typename?: 'StoreSection' }
      & Pick<StoreSection, 'id' | 'title' | 'areItemsShuffled' | 'itemIds'>
    )> }
  ) }
);

export type StoreSectionByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type StoreSectionByIdQuery = (
  { __typename?: 'Query' }
  & { store_section?: Maybe<(
    { __typename?: 'StoreSection' }
    & Pick<StoreSection, 'id' | 'title' | 'areItemsShuffled' | 'itemIds'>
    & { items: Array<(
      { __typename?: 'StoreItem' }
      & Pick<StoreItem, 'id' | 'title'>
    )>, l10n?: Maybe<Array<(
      { __typename?: 'StoreSectionL10n' }
      & Pick<StoreSectionL10n, 'l10n_code' | 'l10n_locale' | 'l10n_osxCode' | 'l10n_osxLocale' | 'title'>
    )>> }
  )> }
);

export type UpdateOrCreateStoreSectionMutationVariables = Exact<{
  section: StoreSectionInput;
}>;


export type UpdateOrCreateStoreSectionMutation = (
  { __typename?: 'Mutation' }
  & { store_updateOrCreateStoreSection: (
    { __typename?: 'StoreSection' }
    & Pick<StoreSection, 'id'>
  ) }
);

export type DeleteStoreSectionsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteStoreSectionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'store_deleteSections'>
);

export const InstrumentFieldsFragmentDoc = `
    fragment InstrumentFields on Instrument {
  id
  intId
  storeItemId
  directoryPath
  name
  category
  updatedDate
  abbreviation
}
    `;
export const InstrumentDefinitionFieldsFragmentDoc = `
    fragment InstrumentDefinitionFields on InstrumentDefinition {
  id
  instrumentId
  samplesId
  format
  revision
  fileName
  createdDate
  samplesFileName
}
    `;
export const StoreItemDemoFieldsFragmentDoc = `
    fragment StoreItemDemoFields on StoreItemDemo {
  title
  type
  url
  l10n {
    l10n_code
    l10n_locale
    l10n_osxCode
    l10n_osxLocale
    title
  }
}
    `;
export const StoreItemFragmentFragmentDoc = `
    fragment StoreItemFragment on StoreItem {
  id
  PurchaseId
  folderName
  instrumentIds
  imageURL
  accent
  instrumentIds
  type {
    ... on StoreItemDetailsTypeRegular {
      regular {
        title
        subtitle
        description
        instrument
        creator
        demos {
          ...StoreItemDemoFields
        }
        l10n {
          l10n_code
          l10n_locale
          l10n_osxCode
          l10n_osxLocale
          title
          subtitle
          description
          instrument
        }
      }
    }
    ... on StoreItemDetailsTypeFeatured {
      featured {
        title
        logoURL
        introduction
        features
        featuredDemos {
          ...StoreItemDemoFields
        }
        demos {
          ...StoreItemDemoFields
        }
        instruments
        genre
        genreIconURL
        released
        size
        sizeUnits
        partner
        website
        l10n {
          l10n_code
          l10n_locale
          l10n_osxCode
          l10n_osxLocale
          title
          introduction
          features
          instruments
          genre
        }
      }
    }
  }
}
    ${StoreItemDemoFieldsFragmentDoc}`;
export const CrowdinFileIdDocument = `
    query CrowdinFileId($entity: L10nEntity!) {
  store_crowdinFileId(l10nEntity: $entity)
}
    `;
export const useCrowdinFileIdQuery = <
      TData = CrowdinFileIdQuery,
      TError = unknown
    >(
      variables: CrowdinFileIdQueryVariables, 
      options?: UseQueryOptions<CrowdinFileIdQuery, TError, TData>
    ) => 
    useQuery<CrowdinFileIdQuery, TError, TData>(
      ['CrowdinFileId', variables],
      fetchData<CrowdinFileIdQuery, CrowdinFileIdQueryVariables>(CrowdinFileIdDocument, variables),
      options
    );
export const ElementsAndTotalCountDocument = `
    query elementsAndTotalCount($filter: ElementFilterInput, $orderBy: ElementSearchOrderByInput, $paging: PagingInput) {
  elements_search(filter: $filter, orderBy: $orderBy, paging: $paging) {
    elements {
      id
      title
      album
      artists
      genres
      publisher
      type
      instrument
      keywords
      bpm
      duration
      key
    }
    elementsCount
  }
}
    `;
export const useElementsAndTotalCountQuery = <
      TData = ElementsAndTotalCountQuery,
      TError = unknown
    >(
      variables?: ElementsAndTotalCountQueryVariables, 
      options?: UseQueryOptions<ElementsAndTotalCountQuery, TError, TData>
    ) => 
    useQuery<ElementsAndTotalCountQuery, TError, TData>(
      ['elementsAndTotalCount', variables],
      fetchData<ElementsAndTotalCountQuery, ElementsAndTotalCountQueryVariables>(ElementsAndTotalCountDocument, variables),
      options
    );
export const DeleteElementsDocument = `
    mutation deleteElements($ids: [String!]!) {
  elements_deleteElements(ids: $ids)
}
    `;
export const useDeleteElementsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteElementsMutation, TError, DeleteElementsMutationVariables, TContext>) => 
    useMutation<DeleteElementsMutation, TError, DeleteElementsMutationVariables, TContext>(
      (variables?: DeleteElementsMutationVariables) => fetchData<DeleteElementsMutation, DeleteElementsMutationVariables>(DeleteElementsDocument, variables)(),
      options
    );
export const SearchInstrumentsDocument = `
    query searchInstruments($filter: InstrumentsFilterInput, $paging: PagingInput) {
  instruments_search(filter: $filter, paging: $paging) {
    totalCount
    instruments {
      ...InstrumentFields
    }
  }
}
    ${InstrumentFieldsFragmentDoc}`;
export const useSearchInstrumentsQuery = <
      TData = SearchInstrumentsQuery,
      TError = unknown
    >(
      variables?: SearchInstrumentsQueryVariables, 
      options?: UseQueryOptions<SearchInstrumentsQuery, TError, TData>
    ) => 
    useQuery<SearchInstrumentsQuery, TError, TData>(
      ['searchInstruments', variables],
      fetchData<SearchInstrumentsQuery, SearchInstrumentsQueryVariables>(SearchInstrumentsDocument, variables),
      options
    );
export const SearchInstrumentDefinitionsDocument = `
    query searchInstrumentDefinitions($instrumentId: String!, $paging: PagingInput) {
  instruments_definitionsSearch(instrumentId: $instrumentId, paging: $paging) {
    totalCount
    definitions {
      ...InstrumentDefinitionFields
    }
  }
}
    ${InstrumentDefinitionFieldsFragmentDoc}`;
export const useSearchInstrumentDefinitionsQuery = <
      TData = SearchInstrumentDefinitionsQuery,
      TError = unknown
    >(
      variables: SearchInstrumentDefinitionsQueryVariables, 
      options?: UseQueryOptions<SearchInstrumentDefinitionsQuery, TError, TData>
    ) => 
    useQuery<SearchInstrumentDefinitionsQuery, TError, TData>(
      ['searchInstrumentDefinitions', variables],
      fetchData<SearchInstrumentDefinitionsQuery, SearchInstrumentDefinitionsQueryVariables>(SearchInstrumentDefinitionsDocument, variables),
      options
    );
export const InstrumentByIdDocument = `
    query instrumentById($id: String!) {
  instrument(id: $id) {
    ...InstrumentFields
  }
}
    ${InstrumentFieldsFragmentDoc}`;
export const useInstrumentByIdQuery = <
      TData = InstrumentByIdQuery,
      TError = unknown
    >(
      variables: InstrumentByIdQueryVariables, 
      options?: UseQueryOptions<InstrumentByIdQuery, TError, TData>
    ) => 
    useQuery<InstrumentByIdQuery, TError, TData>(
      ['instrumentById', variables],
      fetchData<InstrumentByIdQuery, InstrumentByIdQueryVariables>(InstrumentByIdDocument, variables),
      options
    );
export const InstrumentDefinitionByIdDocument = `
    query instrumentDefinitionById($id: String!) {
  instruments_definition(id: $id) {
    ...InstrumentDefinitionFields
  }
}
    ${InstrumentDefinitionFieldsFragmentDoc}`;
export const useInstrumentDefinitionByIdQuery = <
      TData = InstrumentDefinitionByIdQuery,
      TError = unknown
    >(
      variables: InstrumentDefinitionByIdQueryVariables, 
      options?: UseQueryOptions<InstrumentDefinitionByIdQuery, TError, TData>
    ) => 
    useQuery<InstrumentDefinitionByIdQuery, TError, TData>(
      ['instrumentDefinitionById', variables],
      fetchData<InstrumentDefinitionByIdQuery, InstrumentDefinitionByIdQueryVariables>(InstrumentDefinitionByIdDocument, variables),
      options
    );
export const NextInstrumentDefinitionRevisionDocument = `
    query nextInstrumentDefinitionRevision($instrumentId: String!, $format: String!) {
  instruments_getNextDefinitionRevision(
    instrumentId: $instrumentId
    format: $format
  )
}
    `;
export const useNextInstrumentDefinitionRevisionQuery = <
      TData = NextInstrumentDefinitionRevisionQuery,
      TError = unknown
    >(
      variables: NextInstrumentDefinitionRevisionQueryVariables, 
      options?: UseQueryOptions<NextInstrumentDefinitionRevisionQuery, TError, TData>
    ) => 
    useQuery<NextInstrumentDefinitionRevisionQuery, TError, TData>(
      ['nextInstrumentDefinitionRevision', variables],
      fetchData<NextInstrumentDefinitionRevisionQuery, NextInstrumentDefinitionRevisionQueryVariables>(NextInstrumentDefinitionRevisionDocument, variables),
      options
    );
export const UpdateOrCreateInstrumentDocument = `
    mutation updateOrCreateInstrument($instrument: InstrumentInput!) {
  instruments_updateOrCreate(instrument: $instrument) {
    id
  }
}
    `;
export const useUpdateOrCreateInstrumentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOrCreateInstrumentMutation, TError, UpdateOrCreateInstrumentMutationVariables, TContext>) => 
    useMutation<UpdateOrCreateInstrumentMutation, TError, UpdateOrCreateInstrumentMutationVariables, TContext>(
      (variables?: UpdateOrCreateInstrumentMutationVariables) => fetchData<UpdateOrCreateInstrumentMutation, UpdateOrCreateInstrumentMutationVariables>(UpdateOrCreateInstrumentDocument, variables)(),
      options
    );
export const UpdateInstrumentDefinitionDocument = `
    mutation updateInstrumentDefinition($definition: InstrumentDefinitionUpdateInput!) {
  instruments_updateDefinition(definition: $definition) {
    ...InstrumentDefinitionFields
  }
}
    ${InstrumentDefinitionFieldsFragmentDoc}`;
export const useUpdateInstrumentDefinitionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateInstrumentDefinitionMutation, TError, UpdateInstrumentDefinitionMutationVariables, TContext>) => 
    useMutation<UpdateInstrumentDefinitionMutation, TError, UpdateInstrumentDefinitionMutationVariables, TContext>(
      (variables?: UpdateInstrumentDefinitionMutationVariables) => fetchData<UpdateInstrumentDefinitionMutation, UpdateInstrumentDefinitionMutationVariables>(UpdateInstrumentDefinitionDocument, variables)(),
      options
    );
export const DeleteInstrumentsDocument = `
    mutation deleteInstruments($ids: [String!]!) {
  instruments_delete(ids: $ids)
}
    `;
export const useDeleteInstrumentsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteInstrumentsMutation, TError, DeleteInstrumentsMutationVariables, TContext>) => 
    useMutation<DeleteInstrumentsMutation, TError, DeleteInstrumentsMutationVariables, TContext>(
      (variables?: DeleteInstrumentsMutationVariables) => fetchData<DeleteInstrumentsMutation, DeleteInstrumentsMutationVariables>(DeleteInstrumentsDocument, variables)(),
      options
    );
export const CreateInstrumentDefinitionDocument = `
    mutation createInstrumentDefinition($definition: InstrumentDefinitionInput!) {
  instruments_createDefinition(definition: $definition) {
    id
  }
}
    `;
export const useCreateInstrumentDefinitionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateInstrumentDefinitionMutation, TError, CreateInstrumentDefinitionMutationVariables, TContext>) => 
    useMutation<CreateInstrumentDefinitionMutation, TError, CreateInstrumentDefinitionMutationVariables, TContext>(
      (variables?: CreateInstrumentDefinitionMutationVariables) => fetchData<CreateInstrumentDefinitionMutation, CreateInstrumentDefinitionMutationVariables>(CreateInstrumentDefinitionDocument, variables)(),
      options
    );
export const DeleteInstrumentDefinitionsDocument = `
    mutation deleteInstrumentDefinitions($ids: [String!]!) {
  instruments_deleteDefinitions(ids: $ids)
}
    `;
export const useDeleteInstrumentDefinitionsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteInstrumentDefinitionsMutation, TError, DeleteInstrumentDefinitionsMutationVariables, TContext>) => 
    useMutation<DeleteInstrumentDefinitionsMutation, TError, DeleteInstrumentDefinitionsMutationVariables, TContext>(
      (variables?: DeleteInstrumentDefinitionsMutationVariables) => fetchData<DeleteInstrumentDefinitionsMutation, DeleteInstrumentDefinitionsMutationVariables>(DeleteInstrumentDefinitionsDocument, variables)(),
      options
    );
export const StoreItemByIdDocument = `
    query storeItemById($id: String!) {
  store_item(id: $id) {
    ...StoreItemFragment
  }
}
    ${StoreItemFragmentFragmentDoc}`;
export const useStoreItemByIdQuery = <
      TData = StoreItemByIdQuery,
      TError = unknown
    >(
      variables: StoreItemByIdQueryVariables, 
      options?: UseQueryOptions<StoreItemByIdQuery, TError, TData>
    ) => 
    useQuery<StoreItemByIdQuery, TError, TData>(
      ['storeItemById', variables],
      fetchData<StoreItemByIdQuery, StoreItemByIdQueryVariables>(StoreItemByIdDocument, variables),
      options
    );
export const SearchStoreItemsIdTitleDocument = `
    query searchStoreItemsIdTitle($filter: StoreItemsFilterInput, $orderBy: StoreItemsSearchOrderByInput, $paging: PagingInput) {
  store_itemsSearch(filter: $filter, orderBy: $orderBy, paging: $paging) {
    items {
      id
      title
    }
    totalCount
  }
}
    `;
export const useSearchStoreItemsIdTitleQuery = <
      TData = SearchStoreItemsIdTitleQuery,
      TError = unknown
    >(
      variables?: SearchStoreItemsIdTitleQueryVariables, 
      options?: UseQueryOptions<SearchStoreItemsIdTitleQuery, TError, TData>
    ) => 
    useQuery<SearchStoreItemsIdTitleQuery, TError, TData>(
      ['searchStoreItemsIdTitle', variables],
      fetchData<SearchStoreItemsIdTitleQuery, SearchStoreItemsIdTitleQueryVariables>(SearchStoreItemsIdTitleDocument, variables),
      options
    );
export const StoreItemTitlesByIdsDocument = `
    query storeItemTitlesByIds($ids: [String!]!) {
  store_items(ids: $ids) {
    id
    title
  }
}
    `;
export const useStoreItemTitlesByIdsQuery = <
      TData = StoreItemTitlesByIdsQuery,
      TError = unknown
    >(
      variables: StoreItemTitlesByIdsQueryVariables, 
      options?: UseQueryOptions<StoreItemTitlesByIdsQuery, TError, TData>
    ) => 
    useQuery<StoreItemTitlesByIdsQuery, TError, TData>(
      ['storeItemTitlesByIds', variables],
      fetchData<StoreItemTitlesByIdsQuery, StoreItemTitlesByIdsQueryVariables>(StoreItemTitlesByIdsDocument, variables),
      options
    );
export const SearchStoreItemsDocument = `
    query searchStoreItems($filter: StoreItemsFilterInput, $orderBy: StoreItemsSearchOrderByInput, $paging: PagingInput) {
  store_itemsSearch(filter: $filter, orderBy: $orderBy, paging: $paging) {
    items {
      ...StoreItemFragment
    }
    totalCount
  }
}
    ${StoreItemFragmentFragmentDoc}`;
export const useSearchStoreItemsQuery = <
      TData = SearchStoreItemsQuery,
      TError = unknown
    >(
      variables?: SearchStoreItemsQueryVariables, 
      options?: UseQueryOptions<SearchStoreItemsQuery, TError, TData>
    ) => 
    useQuery<SearchStoreItemsQuery, TError, TData>(
      ['searchStoreItems', variables],
      fetchData<SearchStoreItemsQuery, SearchStoreItemsQueryVariables>(SearchStoreItemsDocument, variables),
      options
    );
export const DeleteStoreItemsDocument = `
    mutation deleteStoreItems($ids: [String!]!) {
  store_deleteItems(ids: $ids)
}
    `;
export const useDeleteStoreItemsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteStoreItemsMutation, TError, DeleteStoreItemsMutationVariables, TContext>) => 
    useMutation<DeleteStoreItemsMutation, TError, DeleteStoreItemsMutationVariables, TContext>(
      (variables?: DeleteStoreItemsMutationVariables) => fetchData<DeleteStoreItemsMutation, DeleteStoreItemsMutationVariables>(DeleteStoreItemsDocument, variables)(),
      options
    );
export const UpdateOrCreateFeaturedItemDocument = `
    mutation updateOrCreateFeaturedItem($item: StoreItemFeaturedInput!) {
  store_updateOrCreateFeaturedItem(item: $item) {
    id
  }
}
    `;
export const useUpdateOrCreateFeaturedItemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOrCreateFeaturedItemMutation, TError, UpdateOrCreateFeaturedItemMutationVariables, TContext>) => 
    useMutation<UpdateOrCreateFeaturedItemMutation, TError, UpdateOrCreateFeaturedItemMutationVariables, TContext>(
      (variables?: UpdateOrCreateFeaturedItemMutationVariables) => fetchData<UpdateOrCreateFeaturedItemMutation, UpdateOrCreateFeaturedItemMutationVariables>(UpdateOrCreateFeaturedItemDocument, variables)(),
      options
    );
export const UpdateOrCreateRegularItemDocument = `
    mutation updateOrCreateRegularItem($item: StoreItemRegularInput!) {
  store_updateOrCreateRegularItem(item: $item) {
    id
  }
}
    `;
export const useUpdateOrCreateRegularItemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOrCreateRegularItemMutation, TError, UpdateOrCreateRegularItemMutationVariables, TContext>) => 
    useMutation<UpdateOrCreateRegularItemMutation, TError, UpdateOrCreateRegularItemMutationVariables, TContext>(
      (variables?: UpdateOrCreateRegularItemMutationVariables) => fetchData<UpdateOrCreateRegularItemMutation, UpdateOrCreateRegularItemMutationVariables>(UpdateOrCreateRegularItemDocument, variables)(),
      options
    );
export const SyncStoreTranslationsDocument = `
    mutation syncStoreTranslations {
  store_syncTranslationsWithCrowdin
}
    `;
export const useSyncStoreTranslationsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SyncStoreTranslationsMutation, TError, SyncStoreTranslationsMutationVariables, TContext>) => 
    useMutation<SyncStoreTranslationsMutation, TError, SyncStoreTranslationsMutationVariables, TContext>(
      (variables?: SyncStoreTranslationsMutationVariables) => fetchData<SyncStoreTranslationsMutation, SyncStoreTranslationsMutationVariables>(SyncStoreTranslationsDocument, variables)(),
      options
    );
export const StoreLanguagesDocument = `
    query StoreLanguages {
  store_supportedLanguages {
    code
    name
    locale
    osxCode
    osxLocale
    crowdinEditorCode
  }
}
    `;
export const useStoreLanguagesQuery = <
      TData = StoreLanguagesQuery,
      TError = unknown
    >(
      variables?: StoreLanguagesQueryVariables, 
      options?: UseQueryOptions<StoreLanguagesQuery, TError, TData>
    ) => 
    useQuery<StoreLanguagesQuery, TError, TData>(
      ['StoreLanguages', variables],
      fetchData<StoreLanguagesQuery, StoreLanguagesQueryVariables>(StoreLanguagesDocument, variables),
      options
    );
export const SearchStoreSectionsDocument = `
    query searchStoreSections($filter: StoreSectionFilterInput, $paging: PagingInput) {
  store_sectionsSearch(filter: $filter, paging: $paging) {
    items {
      id
      title
      areItemsShuffled
      itemIds
    }
    totalCount
  }
}
    `;
export const useSearchStoreSectionsQuery = <
      TData = SearchStoreSectionsQuery,
      TError = unknown
    >(
      variables?: SearchStoreSectionsQueryVariables, 
      options?: UseQueryOptions<SearchStoreSectionsQuery, TError, TData>
    ) => 
    useQuery<SearchStoreSectionsQuery, TError, TData>(
      ['searchStoreSections', variables],
      fetchData<SearchStoreSectionsQuery, SearchStoreSectionsQueryVariables>(SearchStoreSectionsDocument, variables),
      options
    );
export const StoreSectionByIdDocument = `
    query storeSectionById($id: String!) {
  store_section(id: $id) {
    id
    title
    areItemsShuffled
    itemIds
    items {
      id
      title
    }
    l10n {
      l10n_code
      l10n_locale
      l10n_osxCode
      l10n_osxLocale
      title
    }
  }
}
    `;
export const useStoreSectionByIdQuery = <
      TData = StoreSectionByIdQuery,
      TError = unknown
    >(
      variables: StoreSectionByIdQueryVariables, 
      options?: UseQueryOptions<StoreSectionByIdQuery, TError, TData>
    ) => 
    useQuery<StoreSectionByIdQuery, TError, TData>(
      ['storeSectionById', variables],
      fetchData<StoreSectionByIdQuery, StoreSectionByIdQueryVariables>(StoreSectionByIdDocument, variables),
      options
    );
export const UpdateOrCreateStoreSectionDocument = `
    mutation updateOrCreateStoreSection($section: StoreSectionInput!) {
  store_updateOrCreateStoreSection(section: $section) {
    id
  }
}
    `;
export const useUpdateOrCreateStoreSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOrCreateStoreSectionMutation, TError, UpdateOrCreateStoreSectionMutationVariables, TContext>) => 
    useMutation<UpdateOrCreateStoreSectionMutation, TError, UpdateOrCreateStoreSectionMutationVariables, TContext>(
      (variables?: UpdateOrCreateStoreSectionMutationVariables) => fetchData<UpdateOrCreateStoreSectionMutation, UpdateOrCreateStoreSectionMutationVariables>(UpdateOrCreateStoreSectionDocument, variables)(),
      options
    );
export const DeleteStoreSectionsDocument = `
    mutation deleteStoreSections($ids: [String!]!) {
  store_deleteSections(ids: $ids)
}
    `;
export const useDeleteStoreSectionsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteStoreSectionsMutation, TError, DeleteStoreSectionsMutationVariables, TContext>) => 
    useMutation<DeleteStoreSectionsMutation, TError, DeleteStoreSectionsMutationVariables, TContext>(
      (variables?: DeleteStoreSectionsMutationVariables) => fetchData<DeleteStoreSectionsMutation, DeleteStoreSectionsMutationVariables>(DeleteStoreSectionsDocument, variables)(),
      options
    );