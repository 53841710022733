import React from "react";
import { useMsal} from "@azure/msal-react";
import { loginRequest } from "../../security/authConfig";
import {
    Button,
} from "@chakra-ui/react"

function handleLogin(instance: any) {
    instance.loginPopup(loginRequest).catch((e: any) => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <Button colorScheme="blue" variant="link" onClick={() => handleLogin(instance)}>Sign in</Button>
    );
}