import {
    StoreItemByIdQuery,
    StoreItemDetailsTypeFeatured,
    StoreItemDetailsTypeRegular
} from "../../../graphql";

export enum StoreItemType {
    regular = "regular",
    featured = "featured"
}

export interface StoreItemInfo {
    id: string,
    title: string,
    type: StoreItemType
    item: Exclude<StoreItemByIdQuery["store_item"], undefined | null>
    imageUrl: string
}

export  const itemInfoFromItem = (item: Exclude<StoreItemByIdQuery["store_item"], undefined | null>): StoreItemInfo => {
    if (item.type.hasOwnProperty(StoreItemType.regular)) {
        const regularItem = item.type as StoreItemDetailsTypeRegular
        return {
            id: item.id,
            title: regularItem.regular.title,
            type: StoreItemType.regular,
            item: item,
            imageUrl: item.imageURL
        }
    } else if(item.type.hasOwnProperty(StoreItemType.featured)) {
        const featuredItem = item.type as StoreItemDetailsTypeFeatured
        return {
            id: item.id,
            title: featuredItem.featured.title,
            type: StoreItemType.featured,
            item: item,
            imageUrl: item.imageURL
        }
    }
    throw new Error("Invalid item type")
}