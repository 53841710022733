import * as React from "react"
import {
  Center,
  Text,
  ChakraProvider, Flex, VStack
} from "@chakra-ui/react"
import {BrowserRouter as Router, Route} from "react-router-dom"
import staffPadTheme from "./theme"
import AppHeader from "./components/Header/AppHeader"
import {DashBoardPage} from "./pages/dashboard/DashboardPage"
import {ProductsPage} from "./pages/products/ProductsPage"
import {UsersPage} from "./pages/users/UsersPage"
import {SettingsPage} from "./pages/settings/SettingsPage"
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import {SignInButton} from "./components/Auth/SignInButton";
import CosmosLogo from "./components/CosmosLogo";
import {SoundEditPage} from "./pages/products/sounds/SoundEditPage";
import {SoundSectionsPage} from "./pages/products/sounds/sections/SoundSectionsPage";
import {SoundSectionsEditPage} from "./pages/products/sounds/sections/SoundSectionEditPage";

const queryClient = new QueryClient()

export const App = () => {

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={staffPadTheme}>
          <AuthenticatedTemplate>
            <AppHeader />
            <Route path={["/dashboard", "/"]} exact component={DashBoardPage}/>
            <Route path="/products" component={ProductsPage}/>
            <Route path="/sounds/:id" component={SoundEditPage}/>
            <Route path="/sound-sections" exact component={SoundSectionsPage}/>
            <Route path="/sound-sections/:id" exact component={SoundSectionsEditPage}/>
            <Route path="/users" component={UsersPage}/>
            <Route path="/settings" component={SettingsPage}/>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Flex justifyContent={"center"} alignItems={"center"} height={"100vh"}>
              <VStack alignItems={"center"} spacing={5}>
                <Center><CosmosLogo width={100}/></Center>
                  <Text fontSize={'2xl'}>Welcome to Cosmos!</Text>
                <SignInButton/>
              </VStack>
            </Flex>
          </UnauthenticatedTemplate>
        </ChakraProvider>
      </QueryClientProvider>
    </Router>
  )
}
