import {
    chakra,
    Flex,
    HStack,
    Link,
    useColorModeValue,
    Text,
    HTMLChakraProps,
    PropsOf,
} from "@chakra-ui/react"
import {useViewportScroll} from "framer-motion"
import React from "react"
import {Link as RouterLink, useRouteMatch} from "react-router-dom";
import {FiDatabase, FiShoppingCart, FiUsers, FiSettings} from "react-icons/fi";
import CosmosLogo from "../CosmosLogo"
import { AuthenticatedTemplate, useMsal} from "@azure/msal-react";
import {SignOutButton} from "../Auth/SignOutButton";


const StyledLink = React.forwardRef(function StyledLink(
    props: PropsOf<typeof chakra.a> & { isActive?: boolean },
    ref: React.Ref<any>,
) {
    const {isActive, ...rest} = props

    return (
        <chakra.a
            aria-current={isActive ? "page" : undefined}
            width="100%"
            px="3"
            py="1"
            rounded="md"
            ref={ref}
            fontSize="sm"
            fontWeight="500"
            color="gray.700"
            transition="all 0.2s"
            _activeLink={{
                bg: "blue.50",
                color: "blue.700",
                fontWeight: "600",
            }}
            {...rest}
        />
    )
})

function HeaderContent() {
    const { accounts } = useMsal();
    const name = accounts[0] && accounts[0].name;
    return (
        <>
            <Flex w="100%" h="100%" px="6" align="center" justify="space-between">
                <Flex align="center">
                    <Link href="/">
                        <CosmosLogo/>
                    </Link>
                </Flex>

                <HStack spacing="10">

                    <StyledLink to="/dashboard" as={RouterLink} isActive={useRouteMatch("/dashboard")}>
                        <HStack><FiDatabase/> <Text> Dashboard</Text></HStack>
                    </StyledLink>
                    <StyledLink to="/products/apps" as={RouterLink} isActive={useRouteMatch(["/products", "/sounds"])}>
                        <HStack><FiShoppingCart/> <Text> Products</Text></HStack>
                    </StyledLink>
                    <StyledLink to="/users" as={RouterLink} isActive={useRouteMatch("/users")}>
                        <HStack><FiUsers/> <Text> Users</Text></HStack>
                    </StyledLink>
                    <StyledLink to="/settings" as={RouterLink} isActive={useRouteMatch("/settings")}>
                        <HStack><FiSettings/> <Text> Settings</Text></HStack>
                    </StyledLink>
                </HStack>

                <HStack spacing="0">
                    <AuthenticatedTemplate>
                        <HStack><Text>Welcome <b>{name}</b></Text><SignOutButton/></HStack>
                    </AuthenticatedTemplate>
                </HStack>

            </Flex>
        </>
    )
}

function AppHeader(props: HTMLChakraProps<"header">) {
    const bg = useColorModeValue("white", "gray.800")
    const ref = React.useRef<HTMLHeadingElement>()
    const [y, setY] = React.useState(0)
    const {height = 0} = ref.current?.getBoundingClientRect() ?? {}

    const {scrollY} = useViewportScroll()
    React.useEffect(() => {
        return scrollY.onChange(() => setY(scrollY.get()))
    }, [scrollY])

    return (

        <chakra.header
            shadow={y > height ? "sm" : undefined}
            transition="box-shadow 0.2s, background-color 0.2s"
            pos="sticky"
            top="0"
            zIndex="3"
            bg={bg}
            left="0"
            right="0"
            width="full"
            {...props}
        >
            <chakra.div height="4.5rem" mx="auto" maxW="8xl">
                <HeaderContent/>
            </chakra.div>
        </chakra.header>
    )
}

export default AppHeader