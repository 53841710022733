import * as React from 'react';
import {Box, Center, Spinner} from "@chakra-ui/react"
import {Route, useParams} from "react-router-dom";
import {Breadcrumb, Sidebar, SidebarItem} from "@staffpad/webapp-common";
import {
    useInstrumentByIdQuery
} from "../../../../../graphql";
import {StoreItemInfo} from "../../SoundInfo";
import {InstrumentInfoPage} from "./instrumentInfo/InstrumentInfoPage";
import {InstrumentDefinitionsPage} from "./instrumentDefinitions/InstrumentDefinitionsPage";
import {InstrumentDefinitionPage} from "./instrumentDefinitions/DefinitionPage/InstrumentDefinitionPage";

type InstrumentEditPagePathParams = {
    soundId: string
    id: string
}

type InstrumentEditPageProps = {
    storeItemInfo: StoreItemInfo
    onUpdated?: (() => void) | undefined
};

export const InstrumentEditPage  = (props: InstrumentEditPageProps) => {
    const {id, soundId} = useParams<InstrumentEditPagePathParams>()
    const {storeItemInfo} = props
    const { onUpdated} = props

    const instrumentByIdQuery = useInstrumentByIdQuery(
        {
            id: id
        },
        {
            staleTime: 1000 * 60 * 3
        }
    )

    const sidebarItems: SidebarItem[] = [
        { title: "Info", mainPath: `/sounds/${soundId}/instruments/${id}/info`, relatedPaths:[] },
        { title: "Definitions", mainPath: `/sounds/${soundId}/instruments/${id}/definitions`, relatedPaths:[] }
    ]

    const instrument = instrumentByIdQuery.data?.instrument

    const onInfoUpdated = React.useCallback(() => {
        //instrumentByIdQuery.remove()
        instrumentByIdQuery.refetch()
        onUpdated?.()
    }, [instrumentByIdQuery, onUpdated])

    return (
        <>
            <Box as="main" className="main-content" w="full" maxW="8xl" mx="auto">
                <Route path={["/sounds/:soundId/instruments/:id/info", "/sounds/:soundId/instruments/:id/definitions"]} exact={true}>
                    <Breadcrumb pathItems={[
                        {name: `Sounds`, path: `/products/sounds`},
                        {name: storeItemInfo.title, path: `/sounds/${storeItemInfo.id}/general`},
                        {name: `Instruments`, path: `/sounds/${storeItemInfo.id}/instruments`},
                        {name: `${instrument?.name ?? ""}`, path: `/sounds/${storeItemInfo.id}/instruments/${id}/info`}
                    ]}/>
                    <Box display={{ md: "flex" }}>
                        <Sidebar items={sidebarItems} />
                        {instrument ?
                            <Box pl="2rem" ml="1rem" flex="1" minW="0" pt="2rem">
                                <Route path="/sounds/:soundId/instruments/:id/info" exact={true}>
                                    <InstrumentInfoPage instrument={instrument} onInfoUpdated={onInfoUpdated}/>
                                </Route>
                                <Route path="/sounds/:soundId/instruments/:id/definitions" exact={true}>
                                    <InstrumentDefinitionsPage instrument={instrument} storeItemInfo={storeItemInfo}/>
                                </Route>
                            </Box>
                            : (<Center p={10}><Spinner size="lg" /></Center>)
                        }
                    </Box>
                </Route>
                <Route path={"/sounds/:soundId/instruments/:instrumentId/definitions/:id"}>
                    { instrument && <InstrumentDefinitionPage storeItemInfo={storeItemInfo} instrument={instrument}/> }
                </Route>
            </Box>
        </>
    )
}