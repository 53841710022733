import * as React from 'react';
import {useState} from 'react';

import {Box, Button, Center, HStack, Menu, MenuButton, MenuItem, MenuList, Spacer, Spinner} from "@chakra-ui/react"
import {Breadcrumb} from "@staffpad/webapp-common"
import {useParams} from "react-router-dom";
import {
    L10nEntity,
    StoreSectionInput,
    useCrowdinFileIdQuery,
    useStoreLanguagesQuery,
    useStoreSectionByIdQuery
} from "../../../../graphql";
import {SoundSectionForm} from "./SoundSectionForm";
import _ from "lodash";
import {
    defaultLanguage,
    Language,
    LocalizationProvider,
    LocalizationState
} from "@staffpad/webapp-common";
import {FiChevronDown} from "react-icons/all";

const crowdinProjectCode_StaffPadStore = "staffpad-store"

type SoundSectionEditPageURLParams = {
    id: string;
};

const NewSectionPage = () => {
    const sectionInput: StoreSectionInput = {
        id: "",
        title: "",
        itemIds: [],
        areItemsShuffled: false
    }
    return (
        <>
            <Breadcrumb pathItems={[
                {name: `Sounds`, path: `/products/sounds`},
                {name: `Store Sections`, path: `/sound-sections`},
                {name: `New Section`, path: `/sound-sections/new-section`}
            ]}/>
            <Box p={4} width={"100%"}>
                <SoundSectionForm section={sectionInput}/>
            </Box>

        </>
    )
}

const ExistingSectionPage = () => {
    const {id} = useParams<SoundSectionEditPageURLParams>()
    const itemByIdQuery = useStoreSectionByIdQuery(
        {
            id: id
        },
        {
            staleTime: 1000 * 60 * 3,
            onSettled: () => {
                //
            },
            onError: (error) => {
                console.error(`Error while fetching store item: ${error}`)
            },
            onSuccess: (data) => {
                //setTotalItemsCount(data.store_itemsSearch.totalCount)
            }
        }
    )

    const languagesQuery = useStoreLanguagesQuery({}, {
        staleTime: 1000 * 60 * 60
    })

    const fileIdQuery = useCrowdinFileIdQuery({entity: L10nEntity.StoreSection})
    const supportedLanguages = _.concat([defaultLanguage], languagesQuery.data?.store_supportedLanguages ?? [])
    const [language, setLanguage] = useState<Language>(defaultLanguage)

    const l10nState: LocalizationState = new LocalizationState(language, crowdinProjectCode_StaffPadStore, fileIdQuery.data?.store_crowdinFileId)

    const section = itemByIdQuery.data?.store_section
    let sectionInput: StoreSectionInput | null = null

    if (section != null) {
        sectionInput = {
            id: section.id,
            l10n: section.l10n,
            title: section.title,
            itemIds: section.itemIds,
            areItemsShuffled: section.areItemsShuffled
        }
    }

    const onFormSave = React.useCallback(() => {
        itemByIdQuery.remove()
    }, [itemByIdQuery])

    return (
        <Box as="main" className="main-content" w="full" maxW="8xl" mx="auto">
            <Breadcrumb pathItems={[
                {name: `Sounds`, path: `/products/sounds`},
                {name: `Store sections`, path: `/sound-sections`},
                {name: `${sectionInput?.title ?? ""}`, path: `/sound-sections/${id}`}
            ]}/>
            <Box display={{md: "flex"}}>
                <Box pl="2rem" ml="1rem" flex="1" minW="0" pt="2rem">
                    <LocalizationProvider localizationState={l10nState}>
                        <Box p={4} width={"100%"}>
                            {itemByIdQuery.isFetching &&
                            (<Center p={10}><Spinner size="lg"/></Center>)
                            }

                            {!itemByIdQuery.isFetching && sectionInput != null && (
                                <>
                                    <HStack marginBottom={5}>
                                        <Spacer/>
                                        <Menu>
                                            <MenuButton as={Button} rightIcon={<FiChevronDown/>}>
                                                {l10nState.isBaseLanguage() ?
                                                    "View translations" :
                                                    language.name
                                                }
                                            </MenuButton>
                                            <MenuList>
                                                {supportedLanguages.map(l => (
                                                    <MenuItem onClick={() => {
                                                        setLanguage(l)
                                                    }}>
                                                        {l.name}
                                                        {l.code === defaultLanguage.code && " (Default)"}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </Menu>
                                    </HStack>
                                    <SoundSectionForm onSave={onFormSave} section={sectionInput}/>
                                </>
                            )}
                        </Box>
                    </LocalizationProvider>
                </Box>
            </Box>
        </Box>
    )
}

export const SoundSectionsEditPage = () => {
    const {id} = useParams<SoundSectionEditPageURLParams>()
    if (id === "new-section") {
        return (<NewSectionPage/>)
    } else {
        return (<ExistingSectionPage/>)
    }
}