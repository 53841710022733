import * as React from 'react';

import {
  Text,
} from "@chakra-ui/react"

export const ServicesPage = () => {
  return (
      <Text>Services page</Text>
  );
};

