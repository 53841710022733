import * as React from 'react';

import {
    Box,
    Button, Center, Checkbox,
    HStack, IconButton, Input, Spacer, Spinner, Table, Tbody, Td,
    Text, Th, Thead, Tr,
} from "@chakra-ui/react"
import {Breadcrumb} from "@staffpad/webapp-common"
import {FiTrash, FiEdit, FiCheck} from "react-icons/fi";
import {
    StoreSection,
    useDeleteStoreSectionsMutation,
    useSearchStoreSectionsQuery
} from "../../../../graphql";
import _ from "lodash";
import {useHistory} from "react-router";
import {Paginator} from "@staffpad/webapp-common";
import {DeleteConfirmAlert} from "@staffpad/webapp-common"

export const SoundSectionsPage = () => {

    const [searchInputValue, setSearchInputValue] = React.useState("")
    const [querySearchValue, setQuerySearchValue] = React.useState("")
    const [selectedItemsIds, setSelectedItemsIds] = React.useState<Set<string>>(new Set())
    const [itemsPerPage] = React.useState(10)
    //const [totalItemsCount, setTotalItemsCount] = React.useState(0)
    const [currentPage, setCurrentPage] = React.useState(0)
    const deleteItemsMutation = useDeleteStoreSectionsMutation()
    const [deleteAlertIsOpen, setDeleteAlertIsOpen] = React.useState(false)

    const storeSectionsQuery = useSearchStoreSectionsQuery(
        {
            filter: {search: querySearchValue},
            paging: {limit: itemsPerPage, offset: (currentPage) * itemsPerPage}
        },
        {
            staleTime: 1000 * 60 * 3,
            onSettled: () => {
                setSelectedItemsIds(new Set())
            },
            onError: (error: any) => {
                console.error(`Error while fetching elements: ${error}`)
            },
            onSuccess: (data: any) => {}
        }
    )

    const totalItemsCount = storeSectionsQuery.data?.store_sectionsSearch.totalCount ?? 0

    const onPageChange = (page: number) => {
        setCurrentPage(page)
        console.log(`current page: ${page}`)
    }

    const setQuerySearchValueDebounced = React.useMemo(() => _.debounce((value: string) => {
        setQuerySearchValue(value)
        setCurrentPage(0)
    }, 700), [])

    const searchFieldValueDidChange = (value: string) => {
        setSearchInputValue(value)
        setQuerySearchValueDebounced(value)
    }

    const onCheckboxChange = React.useCallback((itemId: string, isSelected: boolean) => {
        setSelectedItemsIds((currentIds: Set<string>) => {
            const updatedSet = new Set(currentIds)
            if (isSelected) {
                updatedSet.add(itemId)
            } else {
                updatedSet.delete(itemId)
            }
            return updatedSet
        })
    }, [])

    const onDeleteAlertIsClosed = React.useCallback((deleteConfirmed: boolean) => {
        setDeleteAlertIsOpen(false)
        if (deleteConfirmed) {

            deleteItemsMutation.mutate(
                {
                    ids: Array.from(selectedItemsIds)
                },
                {
                    onSuccess: () => {
                        storeSectionsQuery.refetch()
                    }
                }
            )
            setSelectedItemsIds(new Set())
        }
    }, [selectedItemsIds, deleteItemsMutation, storeSectionsQuery])

    const history = useHistory()

    const onDeleteButtonClick = React.useCallback(() => {
        setDeleteAlertIsOpen(true)
    }, [])

    const onEditButtonClick = React.useCallback((item) => {
        history.push(`/sound-sections/${item.id}`)
    }, [history])

    const onNewButtonClick = React.useCallback((item) => {
        history.push(`/sound-sections/new-section`)
    }, [history])

    const tableRows = storeSectionsQuery.data?.store_sectionsSearch.items.map((item: StoreSection) => {
            return (
                <Tr>
                    <Td>
                        <Checkbox isChecked={selectedItemsIds.has(item.id)}
                                  onChange={(event) => {
                                      onCheckboxChange(item.id, event.target.checked)
                                  }}
                        />
                    </Td>
                    <Td wordBreak={"normal"}>{item.title}</Td>
                    <Td wordBreak={"normal"} isNumeric={true}>{item.itemIds.length}</Td>
                    <Td textAlign={"center"}>
                        {item.areItemsShuffled &&
                        <Center>
                            <FiCheck/>
                        </Center>
                        }
                    </Td>
                    <Td textAlign={"center"} w={"70px"}>
                        <IconButton
                            onClick={() => {
                                onEditButtonClick(item)
                            }}
                            colorScheme="blue"
                            variant={"link"}
                            aria-label="Edit"
                            icon={<FiEdit/>}
                        />
                    </Td>
                </Tr>)
        }
    )

    const table = (
        <>
            <Table variant="simple" mt="2rem">
                <Thead>
                    <Tr>
                        <Th/> {/* Checkbox column*/}
                        <Th>Title</Th>
                        <Th isNumeric={true}>Items Count</Th>
                        <Th textAlign={"center"}>Is shuffled</Th>
                        <Th/>
                    </Tr>
                </Thead>
                <Tbody>
                    {tableRows}
                </Tbody>
            </Table>
        </>
    )

    return (
        <Box as="main" className="main-content" w="full" maxW="8xl" mx="auto">
            <Breadcrumb pathItems={[{name: "Sounds", path: "/products/sounds"}, {name: "Store Sections", path: "/sound-sections"} ]}/>
            <Box display={{ md: "flex" }}>
                <Box pl="2rem" ml="1rem" flex="1" minW="0" pt="2rem">
            <HStack mt="2" spacing={2}>
                <Input placeholder="Search" width={300} value={searchInputValue} onChange={(e) => {
                    searchFieldValueDidChange(e.target.value)
                }}/>
                <Spacer/>
                {(selectedItemsIds.size > 0 && !storeSectionsQuery.isFetching) &&
                    <Button leftIcon={<FiTrash/>} colorScheme="red" variant="outline" onClick={onDeleteButtonClick}>
                        Delete Selected
                    </Button>
                }
                <Button leftIcon={<FiTrash/>} colorScheme="blue" variant="outline" onClick={onNewButtonClick}>
                    Add Section
                </Button>
            </HStack>
            {storeSectionsQuery.isFetching && (<Center p={10}><Spinner size="lg" /></Center>)}
            {deleteItemsMutation.isLoading && (<Center><Text p="4rem">Deleting items...</Text></Center>)}

            {!(storeSectionsQuery.isFetching || deleteItemsMutation.isLoading) && (
                (storeSectionsQuery.data?.store_sectionsSearch.totalCount ?? 0) > 0 ?
                    (
                        <>
                            {table}
                            <Paginator totalItemsCount={totalItemsCount} pageSize={itemsPerPage}
                                       currentPage={currentPage} setCurrentPage={onPageChange}/>
                        </>
                    ) :
                    (
                        <Center><Text p="4rem">No data to display</Text></Center>
                    )
            )
            }
            <DeleteConfirmAlert isOpen={deleteAlertIsOpen}
                                onClose={onDeleteAlertIsClosed}
                                title={"Delete Sections"}
                                description={"Are you sure you want to delete selected sections?"}
            />
                </Box>
            </Box>
        </Box>
    );
};

