import * as React from 'react';
import {Box} from "@chakra-ui/react"
import {InstrumentFieldsFragment} from "../../../../../../graphql";
import {InstrumentInfoForm} from "./InstrumentInfoForm";

type InstrumentInfoPageProps = {
    instrument: InstrumentFieldsFragment;
    onInfoUpdated?: (() => void) | undefined
};

export const InstrumentInfoPage  = (props: InstrumentInfoPageProps) => {
    const {instrument, onInfoUpdated} = props

    const onFormSave = React.useCallback(() => {
        onInfoUpdated?.()
    }, [ onInfoUpdated])

    return (
        <>
            <Box p={4} width={"100%"}>
                <InstrumentInfoForm onSave={onFormSave} instrument={instrument}/>
            </Box>
        </>
    )
}