import * as React from 'react';
import {Box, Button, HStack, Menu, MenuButton, MenuItem, MenuList, Spacer} from "@chakra-ui/react"
import {FiChevronDown} from "react-icons/all";
import {defaultLanguage, Language, LocalizationState, LocalizationProvider} from "@staffpad/webapp-common";
import {FeaturedItemForm} from "./FeaturedItemForm";
import {RegularItemForm} from "./RegularItemForm";
import {
    L10nEntity, StoreItemDetailsTypeFeatured, StoreItemDetailsTypeRegular,
    StoreItemFeaturedInput, StoreItemRegularInput,
    useCrowdinFileIdQuery,
    useStoreLanguagesQuery
} from "../../../graphql";
import { StoreItemInfo, StoreItemType} from "./SoundInfo";
import _ from "lodash";
import {useState} from "react";
const crowdinProjectCode_StaffPadStore = "staffpad-store"

type SoundEditGeneralPageProps = {
    itemInfo: StoreItemInfo;
    onInfoUpdated?: (() => void) | undefined
};

export const SoundEditGeneralPage  = (props: SoundEditGeneralPageProps) => {
    const {itemInfo, onInfoUpdated} = props

    const languagesQuery = useStoreLanguagesQuery({}, {
        staleTime: 1000 * 60 * 60
    })

    const fileIdQuery = useCrowdinFileIdQuery({entity: L10nEntity.StoreItem})

    let featuredItemInput: StoreItemFeaturedInput | null = null
    let regularItemInput: StoreItemRegularInput | null = null

    if (itemInfo != null && itemInfo.type === StoreItemType.featured) {
        featuredItemInput = {
            id: itemInfo.item.id,
            accent: itemInfo.item.accent,
            folderName: itemInfo.item.folderName,
            instrumentIds: itemInfo.item.instrumentIds,
            PurchaseId: itemInfo.item.PurchaseId,
            imageURL: itemInfo.item.imageURL,
            featuredDetails: {
                ...((itemInfo.item.type as StoreItemDetailsTypeFeatured).featured)
            }
        }
    }

    if (itemInfo != null && itemInfo.type === StoreItemType.regular) {
        regularItemInput = {
            id: itemInfo.item.id,
            accent: itemInfo.item.accent,
            folderName: itemInfo.item.folderName,
            instrumentIds: itemInfo.item.instrumentIds,
            PurchaseId: itemInfo.item.PurchaseId,
            imageURL: itemInfo.item.imageURL,
            regularDetails: {
                ...((itemInfo.item.type as StoreItemDetailsTypeRegular).regular)
            }
        }
    }

    const onFormSave = React.useCallback(() => {
        onInfoUpdated?.()
    }, [onInfoUpdated])

    const supportedLanguages = _.concat([defaultLanguage], languagesQuery.data?.store_supportedLanguages ?? [] )
    const [language, setLanguage] = useState<Language>(defaultLanguage)
    const l10nState: LocalizationState = new LocalizationState(language, crowdinProjectCode_StaffPadStore, fileIdQuery.data?.store_crowdinFileId)

    return (
        <LocalizationProvider localizationState={l10nState}>
        <Box pl="2rem" ml="1rem" flex="1" minW="0" pt="2rem">

            <Box p={4} width={"100%"}>
                { itemInfo != null && (
                    <>
                        <HStack marginBottom={5}>
                            <Spacer/>
                            <Menu>
                                <MenuButton as={Button} rightIcon={<FiChevronDown />}>
                                    {l10nState.isBaseLanguage() ?
                                        "View translations" :
                                        language.name
                                    }

                                </MenuButton>
                                <MenuList>
                                    {supportedLanguages.map(l => (
                                        <MenuItem onClick={()=>{setLanguage(l)}}>
                                            {l.name}
                                            {l.code === defaultLanguage.code && " (Default)"}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Menu>
                        </HStack>
                        {featuredItemInput != null &&
                        <FeaturedItemForm onSave={onFormSave} featuredItem={featuredItemInput} />
                        }
                        {regularItemInput != null &&
                        <RegularItemForm onSave={onFormSave} regularItem={regularItemInput}/>
                        }
                    </>
                )}
            </Box>
        </Box>
        </LocalizationProvider>
    )
}