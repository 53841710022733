import * as React from 'react';

import {
    Box,
    Button,
    Center,
    CircularProgress,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from "@chakra-ui/react"
import {useSyncStoreTranslationsMutation} from "../../graphql";
import {FiRefreshCw} from "react-icons/fi";

export const SettingsPage = () => {
    const syncTranslationMutation = useSyncStoreTranslationsMutation()
    const onSyncTranslationsButtonClick = React.useCallback(() => {
        syncTranslationMutation.mutate({})
    }, [syncTranslationMutation])

    const syncTranslationsProgressModal = (
        <Modal isOpen={syncTranslationMutation.isLoading} onClose={()=>{}} isCentered>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>
                    <Center>
                        <Text>Synchronizing Translations with Crowdin...</Text>
                    </Center>
                </ModalHeader>
                <ModalBody p={5}>
                    <VStack alignItems={"center"} spacing={5}>
                        <Text>Please wait, this could take a few minutes.</Text>
                        <CircularProgress isIndeterminate size="100px" />
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
    return (
        <Box>
                <Center py="12" >
                    <Button leftIcon={<FiRefreshCw/>} colorScheme="blue" variant="outline"
                            onClick={onSyncTranslationsButtonClick}>
                        Sync Translations
                    </Button>
                </Center>

            {syncTranslationsProgressModal}
        </Box>
    );
}