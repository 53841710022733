import * as React from 'react';

import {
  Text,
} from "@chakra-ui/react"

export const SubscriptionsPage = () => {
  return (
      <Text>Subscriptions page</Text>
  );
};

