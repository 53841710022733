import * as React from 'react';
import {Box, Center, Spinner} from "@chakra-ui/react"
import {useParams} from "react-router-dom";
import {Breadcrumb} from "@staffpad/webapp-common";

import {
    InstrumentDefinitionFieldsFragment,
    InstrumentFieldsFragment,
    useInstrumentDefinitionByIdQuery
} from "../../../../../../../graphql";
import {StoreItemInfo} from "../../../../SoundInfo";
import {InstrumentDefinitionForm} from "./InstrumentDefinitionForm";

type InstrumentDefinitionPagePathParams = {
    id: string
}

type InstrumentDefinitionPageProps = {
    storeItemInfo: StoreItemInfo
    instrument: InstrumentFieldsFragment
    onUpdated?: (() => void) | undefined
};

export const InstrumentDefinitionPage  = (props: InstrumentDefinitionPageProps) => {
    const {id} = useParams<InstrumentDefinitionPagePathParams>()
    const {storeItemInfo, instrument} = props

    const definitionByIdQuery = useInstrumentDefinitionByIdQuery(
        {
            id: id
        },
        {
            cacheTime: 0,
            refetchOnMount: true,
            staleTime: 1000 * 60 * 3
        }
    )

    const definition: InstrumentDefinitionFieldsFragment | undefined = definitionByIdQuery.data?.instruments_definition

    // const onInfoUpdated = React.useCallback(() => {
    //     //instrumentByIdQuery.remove()
    //     definitionByIdQuery.refetch()
    //     onUpdated?.()
    // }, [])

    return (
        <>
            <Box as="main" className="main-content" w="full" maxW="8xl" mx="auto">
                <Breadcrumb pathItems={[
                    {name: `Sounds`, path: `/products/sounds`},
                    {name: storeItemInfo.title, path: `/sounds/${storeItemInfo.id}/general`},
                    {name: `Instruments`, path: `/sounds/${storeItemInfo.id}/instruments`},
                    {name: `${instrument.name}`, path: `/sounds/${storeItemInfo.id}/instruments/${instrument.id}`},
                    {name: `Definitions`, path: `/sounds/${storeItemInfo.id}/instruments/${instrument.id}/definitions`},
                    {name: `${definition?.format} revision ${definition?.revision}`, path: `/sounds/${storeItemInfo.id}/instruments/${instrument.id}/definitions/${id}`}
                ]}/>
                <Box display={{ md: "flex" }}>
                    {definition ?
                        <Box pl="2rem" ml="1rem" flex="1" minW="0" pt="2rem">
                            <InstrumentDefinitionForm  definition={definition}/>
                        </Box>
                        : (<Center p={10}><Spinner size="lg" /></Center>)
                    }
                </Box>
            </Box>
        </>
    )
}