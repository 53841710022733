import * as React from 'react';

import {
    Button,
    VStack,
} from "@chakra-ui/react"

import {
    StoreItemFeaturedInput, useUpdateOrCreateFeaturedItemMutation,
} from "../../../graphql";
import {
    Formik,
    Form,
    FormikErrors
} from 'formik';
import {createGreaterThanZeroValidator, createRequiredValidator} from "@staffpad/webapp-common";
import {DemosFormSection} from "./DemosFormSection";
import {FormFieldStringList} from "@staffpad/webapp-common";
import {StoreItemCommonFieldsSection} from "./StoreItemCommonFieldsSection";
import {FormFieldText} from "@staffpad/webapp-common";
import {FormFieldTextArea} from "@staffpad/webapp-common";
import {FormFieldSelect} from "@staffpad/webapp-common";
import {FormFieldFloat} from "@staffpad/webapp-common";
import {FormFieldInt} from "@staffpad/webapp-common";
import {useHistory} from "react-router";
import {FormFieldImage} from "@staffpad/webapp-common";
import {imageUploadService} from "../../../service";

interface FeaturedItemFormProps {
    featuredItem: StoreItemFeaturedInput
    onSave?: (() => void) | undefined
}

export const FeaturedItemForm = (props: FeaturedItemFormProps) => {
    const {onSave} = props

    const validateForm = (values: StoreItemFeaturedInput): FormikErrors<any> | undefined => {
        console.log("validate form", values)
        let errors: FormikErrors<any> = {}
        if (values.PurchaseId.length === 0) {
            errors.PurchaseId_Array = "Purchase IDs list can not by empty"
        }
        if (values.folderName.length === 0) {
            errors.folderName_Array = "Folder Names list can not by empty"
        }
        return errors
    }

    const history = useHistory()

    const updateOrCreateFeaturedItemMutation = useUpdateOrCreateFeaturedItemMutation({
        onSuccess: data => {
            console.log("Item successfully updated", data)
            if(onSave) {
                onSave()
            }
            history.push(`/sounds/${data.store_updateOrCreateFeaturedItem.id}/general`)
        },
        onError: error => {
            console.error(error)
        }
    })

    return (
        <Formik validateOnChange={false}
                validate={validateForm}
            initialValues={props.featuredItem}

            onSubmit={async (values, actions) => {
                await updateOrCreateFeaturedItemMutation.mutateAsync({
                    item: values
                })
                actions.setSubmitting(false)
            }}
        >
            {(props) => (
                <Form>
                    <VStack spacing={6} alignItems={"start"}>
                        <StoreItemCommonFieldsSection/>

                        <FormFieldText fieldId={"featuredDetails.title"}
                                       isLocalized={true}
                                       l10nStringKeyFieldName={`${props.values.id}/title`}
                                       fieldTitle={"Title"}
                                       validatorFactory={createRequiredValidator}/>

                        <FormFieldImage fieldId={"featuredDetails.logoURL"}
                                       fieldTitle={"Logo Image"}
                                       validatorFactory={createRequiredValidator}
                                        imageUploadService={imageUploadService}
                        />
                        <FormFieldTextArea fieldId={"featuredDetails.introduction"}
                                           minHeight={"300px"}
                                           isLocalized={true}
                                           l10nStringKeyFieldName={`${props.values.id}/introduction`}
                                           fieldTitle={"Introduction"}
                                           validatorFactory={createRequiredValidator}/>

                        <FormFieldStringList fieldId={"featuredDetails.features"} fieldTitle={"Features"}
                                             isLocalized={true}
                                             l10nStringKeyFieldName={`${props.values.id}/features`}
                                             fieldTitleSingular={"Feature"}/>
                        <DemosFormSection fieldName={"featuredDetails.featuredDemos"} l10nKeyPrefix={"featuredDemo"} fieldTitle={"Featured Demos"}/>
                        <DemosFormSection fieldName={"featuredDetails.demos"} l10nKeyPrefix={"demo"} fieldTitle={"Demos"}/>
                        <FormFieldStringList fieldId={"featuredDetails.instruments"} fieldTitle={"Instruments"}
                                             isLocalized={true}
                                             l10nStringKeyFieldName={`${props.values.id}/instruments`}
                                             fieldTitleSingular={"Instrument"}/>

                        <FormFieldText fieldId={"featuredDetails.genre"}
                                       fieldTitle={"Genre"}
                                       validatorFactory={createRequiredValidator}/>

                        <FormFieldText fieldId={"featuredDetails.genreIconURL"}
                                       fieldTitle={"Genre Icon URL"}
                                       validatorFactory={createRequiredValidator}/>

                        <FormFieldInt fieldId={"featuredDetails.released"}
                                        fieldTitle={"Released year"}
                                        min={2015}
                                        max={new Date().getFullYear() + 1}
                                        validatorFactory={createGreaterThanZeroValidator}/>

                        <FormFieldFloat fieldId={"featuredDetails.size"}
                                        fieldTitle={"Size"}
                                        precision={2}
                                        min={0}/>

                        <FormFieldSelect fieldId={"featuredDetails.sizeUnits"}
                                         fieldTitle={"Size Units"}
                                         options={[
                                             {value: "GB", title: "GB"},
                                             {value: "MB", title: "MB"}
                                         ]}
                                         validatorFactory={createRequiredValidator}/>

                        <FormFieldText fieldId={"featuredDetails.partner"}
                                       fieldTitle={"Partner"}
                                       validatorFactory={createRequiredValidator}/>

                        <FormFieldText fieldId={"featuredDetails.website"}
                                       fieldTitle={"Website"}
                                       validatorFactory={createRequiredValidator}/>

                        <Button
                            mt={4}
                            colorScheme="blue"
                            isLoading={props.isSubmitting}
                            type="submit">
                            Save
                        </Button>
                    </VStack>
                </Form>
            )}
        </Formik>
    )
}