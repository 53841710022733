import * as React from 'react';

import {Button, VStack, HStack, Spacer} from "@chakra-ui/react"
import {Form, Formik} from 'formik';
import {createRequiredValidator} from "@staffpad/webapp-common";
import {FormFieldText, FormFieldFile} from "@staffpad/webapp-common";
import {
    InstrumentDefinitionInput,
    useCreateInstrumentDefinitionMutation
} from "../../../../../../../graphql";
import {instrumentDefinitionUploadService} from "../../../../../../../service";

interface NewDefinitionFormProps {
    instrumentId: string
    onSave?: ((id: string) => void) | undefined
    onCancel: () => void
}


export const NewDefinitionForm = (props: NewDefinitionFormProps) => {
    const {onSave, onCancel, instrumentId} = props

    const createMutation = useCreateInstrumentDefinitionMutation({
        onSuccess: data => {
            console.log("Item successfully updated", data)
            if (onSave && data.instruments_createDefinition?.id) {
                onSave(data.instruments_createDefinition?.id)
            }
        },
        onError: error => {
            console.error(error)
        }
    })

    return (
        <Formik validateOnChange={false} validateOnBlur={false}
                initialValues={{
                    instrumentId: instrumentId,
                    format: "",
                    fileName: ""
                }}

                onSubmit={async (values: InstrumentDefinitionInput, actions) => {
                    console.log("onSubmit")
                    await createMutation.mutateAsync({
                        definition: values
                    })
                    actions.setSubmitting(false)
                }}
        >
            {(props) => (
                <Form>
                    <VStack spacing={6} alignItems={"start"}>
                        <FormFieldText fieldId={"format"}
                                       fieldTitle={"Format"}
                                       validatorFactory={createRequiredValidator}/>
                        <FormFieldFile fieldId={"fileName"} fieldTitle={"Build file"} dropZoneWidth={"100%"}
                                       fieldValueFunc={result => result.fileName}
                                       fileUploadService={instrumentDefinitionUploadService}
                                       isRequired={true}/>
                    </VStack>

                    <HStack spacing={"10px"} mt={"50px"}>
                        <Spacer/>
                        <Button
                            colorScheme="blue"
                            isLoading={props.isSubmitting}
                            type="submit">
                            Create Definition
                        </Button>
                        <Button onClick={onCancel}>
                            Cancel
                        </Button>
                    </HStack>

                </Form>
            )}
        </Formik>
    )
}