import * as React from 'react';

import {
  Text,
} from "@chakra-ui/react"

export const AppsPage = () => {
  return (
      <Text>Apps page</Text>
  );
};

