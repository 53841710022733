import * as React from 'react';

import {Button, VStack, FormLabel, FormControl, Text, HStack} from "@chakra-ui/react"
import {Form, Formik, FormikErrors} from 'formik';
import {
    InstrumentDefinitionFieldsFragment,
    InstrumentDefinitionUpdateInput,
    useUpdateInstrumentDefinitionMutation
} from "../../../../../../../graphql";

interface InstrumentDefinitionFormProps {
    definition: InstrumentDefinitionFieldsFragment
    onSave?: (() => void) | undefined
}

export const InstrumentDefinitionForm = (props: InstrumentDefinitionFormProps) => {
    const {onSave, definition} = props

    const versionInput: InstrumentDefinitionUpdateInput = {
        id: definition.id,
        samplesId: definition.samplesId
    }

    const validateForm = (values: InstrumentDefinitionUpdateInput): FormikErrors<any> | undefined => {
        return {}
    }

    const updateMutation = useUpdateInstrumentDefinitionMutation({
        onSuccess: data => {
            console.log("Instrument definition successfully updated", data)
            if (onSave) {
                onSave()
            }
        },
        onError: error => {
            console.error(error)
        }
    })

    return (
        <>
            <Formik validateOnChange={false}
                    initialValues={versionInput}
                    validate={validateForm}
                    onSubmit={async (values: InstrumentDefinitionUpdateInput, actions) => {
                        await updateMutation.mutateAsync({
                            definition: values
                        })
                        actions.setSubmitting(false)
                    }}
            >
                {(props) => (
                    <Form>
                        <VStack spacing={6} alignItems={"start"}>

                            <FormControl>
                                <FormLabel>Format</FormLabel>
                                <Text>{definition.format}</Text>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Revision</FormLabel>
                                <Text>{definition.revision}</Text>
                            </FormControl>
                            <FormControl>
                                <FormLabel>File Name</FormLabel>
                                <Text>{definition.fileName}</Text>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Samples File Name</FormLabel>
                                <Text>{definition.samplesFileName ?? "Not set"}</Text>
                            </FormControl>

                            <HStack>
                                <Button
                                    colorScheme="blue"
                                    isLoading={props.isSubmitting}
                                    type="submit">
                                    Save
                                </Button>
                            </HStack>
                        </VStack>
                    </Form>
                )}
            </Formik>
        </>
    )
}