import { extendTheme } from "@chakra-ui/react"
// 2. Add your color mode config
// const config : ThemeConfig = {
//   initialColorMode: "light",
//   useSystemColorMode: false,

// }
// 3. extend the theme
const theme = extendTheme({
    components: {
        Link: {
            baseStyle: (props: any) => ({
                color: props.colorMode === "dark" ? "blue.200" : "blue.500"
            })
        },
        Spinner: {
            baseStyle: (props: any) => ({
                emptyColor: "gray.200",
                color: "blue.500"
            })
        }
    },
})
export default theme