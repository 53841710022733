import {getAuthenticationToken} from "../security/getAuthenticationToken";

export const fetchData = <TData, TVariables>(query: string, variables?: TVariables): (() => Promise<TData>) => {
    return async () => {

        const authToken = await getAuthenticationToken()

        const res = await fetch(`${process.env.REACT_APP_WEBSERVICE_BASE_URL}/graphql`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({
                query,
                variables,
            }),
        });

        const json = await res.json();

        if (json.errors) {
            const { message } = json.errors[0] || 'Error..';
            throw new Error(message);
        }

        return json.data;
    };
};