import * as React from 'react';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Center,
    Text
} from "@chakra-ui/react"
import {NewDefinitionForm} from "./NewDefinitionForm";

export interface NewDefinitionDialogProps {
    instrumentId: string
    isOpen: boolean
    onClose: ()=>void
    onSave: (appId: string) => void
}

export const NewDefinitionDialog = (props: NewDefinitionDialogProps) => {
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>
                    <Center>
                        <Text>Create new instrument definition</Text>
                    </Center>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody p={5}>
                    <NewDefinitionForm instrumentId={props.instrumentId} onCancel={props.onClose} onSave={props.onSave}/>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}