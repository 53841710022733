import * as React from 'react';

import {
    Box,
    Button,
    Center,
    Checkbox,
    HStack,
    IconButton,
    Image,
    Input,
    Spacer,
    Spinner,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react"
import {FiTrash, FiEdit, FiPlus, FiGrid} from "react-icons/fi";
import {
    useDeleteStoreItemsMutation,
    useSearchStoreItemsQuery
} from "../../../graphql";
import _ from "lodash";
import {useHistory} from "react-router";
import {Paginator} from "@staffpad/webapp-common";
import {DeleteConfirmAlert} from "@staffpad/webapp-common"
import {itemInfoFromItem} from "./SoundInfo";

export const SoundsPage = () => {
    const [searchInputValue, setSearchInputValue] = React.useState("")
    const [querySearchValue, setQuerySearchValue] = React.useState("")
    const [selectedItemsIds, setSelectedItemsIds] = React.useState<Set<string>>(new Set())
    const [itemsPerPage] = React.useState(10)
    const [currentPage, setCurrentPage] = React.useState(0)
    const deleteItemsMutation = useDeleteStoreItemsMutation()
    const [deleteAlertIsOpen, setDeleteAlertIsOpen] = React.useState(false)

    const storeItemsQuery = useSearchStoreItemsQuery(
        {
            filter: {search: querySearchValue},
            paging: {limit: itemsPerPage, offset: (currentPage) * itemsPerPage}
        },
        {
            staleTime: 1000 * 60 * 3,
            cacheTime: 0,
            refetchOnMount: true,
            onSettled: () => {
                setSelectedItemsIds(new Set())
            },
            onError: (error) => {
                console.error(`Error while fetching elements: ${error}`)
            }
        }
    )

    const totalItemsCount = storeItemsQuery.data?.store_itemsSearch.totalCount ?? 0

    const onPageChange = (page: number) => {
        setCurrentPage(page)
        console.log(`current page: ${page}`)
    }

    const setQuerySearchValueDebounced = React.useMemo(() => _.debounce((value: string) => {
        setQuerySearchValue(value)
        setCurrentPage(0)
    }, 700), [])

    const searchFieldValueDidChange = (value: string) => {
        setSearchInputValue(value)
        setQuerySearchValueDebounced(value)
    }

    const onCheckboxChange = React.useCallback((itemId: string, isSelected: boolean) => {
        setSelectedItemsIds((currentIds: Set<string>) => {
            const updatedSet = new Set(currentIds)
            if (isSelected) {
                updatedSet.add(itemId)
            } else {
                updatedSet.delete(itemId)
            }
            return updatedSet
        })
    }, [])

    const onDeleteAlertIsClosed = React.useCallback((deleteConfirmed: boolean) => {
        setDeleteAlertIsOpen(false)
        if (deleteConfirmed) {

            deleteItemsMutation.mutate(
                {
                    ids: Array.from(selectedItemsIds)
                },
                {
                    onSuccess: () => {
                        storeItemsQuery.refetch()
                    }
                }
            )
            setSelectedItemsIds(new Set())
        }
    }, [selectedItemsIds, deleteItemsMutation, storeItemsQuery])

    const history = useHistory()

    const onAddFeaturedSoundButtonClick = React.useCallback(() => {
        history.push("/sounds/new-featured/general")
    }, [history])

    const onAddRegularSoundButtonClick = React.useCallback(() => {
        history.push("/sounds/new-regular/general")
    }, [history])

    const onDeleteButtonClick = React.useCallback(() => {
        setDeleteAlertIsOpen(true)
    }, [])

    const onEditButtonClick = React.useCallback((item) => {
        history.push(`/sounds/${item.id}/general`)
    }, [history])

    const onManageSectionsButtonClick = React.useCallback(() => {
        history.push("/sound-sections")
    }, [history])


    const tableRows = storeItemsQuery.data?.store_itemsSearch.items.map((item) => {
            const itemInfo = itemInfoFromItem(item)

            return (
                <Tr key={itemInfo.id}><Td w={"70px"}>
                        <Checkbox isChecked={selectedItemsIds.has(item.id)}
                                  onChange={(event) => {
                                      onCheckboxChange(item.id, event.target.checked)
                                  }}
                        />
                    </Td>
                    <Td w={"100px"}><Image align={"left"} boxSize={"50px"} src={itemInfo.imageUrl} /></Td>
                    <Td wordBreak={"normal"}>{itemInfo.title}</Td>
                    <Td wordBreak={"normal"}>{itemInfo.type}</Td>
                    <Td textAlign={"center"} w={"70px"}>
                        <IconButton
                            onClick={() => {
                                onEditButtonClick(item)
                            }}
                            colorScheme="blue"
                            variant={"link"}
                            aria-label="Edit"
                            icon={<FiEdit/>}
                        />
                    </Td></Tr>)
        }
    )


    const table = (
        <>
            <Table variant="simple" mt="2rem">
                <Thead>
                    <Tr>
                        <Th/> {/* Checkbox column*/}
                        <Th/> {/* Image column*/}
                        <Th>Title</Th>
                        <Th>Type</Th>
                        <Th/>
                    </Tr>
                </Thead>
                <Tbody>
                    {tableRows}
                </Tbody>
            </Table>
        </>
    )

    return (
        <Box>
            {/*<Breadcrumb pathItems={[{name: "Sounds", path: "/products/sounds"}]}/>*/}
            <HStack mt="2" spacing={2}>
                <Box py="4">
                    <Input placeholder="Search" width={300} value={searchInputValue} onChange={(e) => {
                        searchFieldValueDidChange(e.target.value)
                    }}/>
                </Box>
                <Spacer/>
                {(selectedItemsIds.size > 0 && !storeItemsQuery.isFetching) &&
                    <Button leftIcon={<FiTrash/>} colorScheme="red" variant="outline" onClick={onDeleteButtonClick}>
                        Delete Selected
                    </Button>
                }
                <Box py="4" >
                    <Button leftIcon={<FiPlus/>} colorScheme="blue" variant="outline"
                            onClick={onAddRegularSoundButtonClick}>
                        Add Regular
                    </Button>
                </Box>
                <Box py="4" >
                <Button leftIcon={<FiPlus/>} colorScheme="blue" variant="outline"
                        onClick={onAddFeaturedSoundButtonClick}>
                    Add Featured
                </Button>
                </Box>
                <Box py="4" >
                <Button leftIcon={<FiGrid/>} colorScheme="blue" variant="outline"
                        onClick={onManageSectionsButtonClick}>
                    Store Sections
                </Button>
                </Box>

            </HStack>
            {storeItemsQuery.isFetching && (<Center p={10}><Spinner size="lg"/></Center>)}
            {deleteItemsMutation.isLoading && (<Center><Text p="4rem">Deleting items...</Text></Center>)}

            {!(storeItemsQuery.isFetching || deleteItemsMutation.isLoading) && (
                (storeItemsQuery.data?.store_itemsSearch.totalCount ?? 0) > 0 ?
                    (
                        <>
                            {table}
                            <Paginator totalItemsCount={totalItemsCount} pageSize={itemsPerPage}
                                       currentPage={currentPage} setCurrentPage={onPageChange}/>
                        </>
                    ) :
                    (
                        <Center><Text p="4rem">No data to display</Text></Center>
                    )
            )
            }
            <DeleteConfirmAlert isOpen={deleteAlertIsOpen}
                                onClose={onDeleteAlertIsClosed}
                                title={"Delete Sounds"}
                                description={"Are you sure you want to delete selected sounds?"}
            />


        </Box>
    );
};

