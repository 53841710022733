import * as React from "react";
import {createRequiredValidator} from "@staffpad/webapp-common";
import {FormFieldStringList} from "@staffpad/webapp-common";
import {FormFieldText} from "@staffpad/webapp-common";
import {FormFieldImage} from "@staffpad/webapp-common";
import {imageUploadService} from "../../../service";
import {useFormikContext} from "formik";
import {fetchData} from "../../../graphql/fetcher";
import {
    StoreItemByIdDocument,
    StoreItemByIdQuery,
    StoreItemByIdQueryVariables
} from "../../../graphql";

const createValidatorFactoryForIdField: (originalId: string | null)=>(fieldTitle: string)=>((value: string)=>Promise<string|undefined>) =
    (originalId) => {
        return (fieldTitle) => {
            return async (value) => {
                console.log("original", originalId)
                if (!value) {
                    return "ID is required"
                }
                if (value !== originalId) {
                    const fetchItemById = fetchData<StoreItemByIdQuery,
                        StoreItemByIdQueryVariables>(StoreItemByIdDocument, {
                        id: value
                    })
                    const result = await fetchItemById()
                    if (result.store_item) {
                        return "Sound with this ID already exists. Choose another one."
                    }
                }
            }
        }
}

export const StoreItemCommonFieldsSection = () => {
    const {initialValues} = useFormikContext<{id: string}>()
    const validatorFactory = createValidatorFactoryForIdField(initialValues.id)

    return (
        <>
            <FormFieldText fieldId={"id"} fieldTitle={"ID"} validatorFactory={validatorFactory}/>
            <FormFieldStringList fieldId={"PurchaseId"}
                                 fieldTitle={"Purchase IDs"}
                                 fieldTitleSingular={"Purchase ID"}/>
            <FormFieldStringList fieldId={"folderName"}
                                 fieldTitle={"Folder Names"}
                                 fieldTitleSingular={"Folder Name"}/>
            <FormFieldImage fieldId={"imageURL"} fieldTitle={"Cover Image"}
                            validatorFactory={createRequiredValidator} imageUploadService={imageUploadService}/>
        </>
    )
}