import * as React from 'react';

import {
    Button,
    VStack
} from "@chakra-ui/react"

import {
    StoreItemRegularInput, useUpdateOrCreateRegularItemMutation,
} from "../../../graphql";
import {
    Formik,
    Form, FormikErrors
} from 'formik';
import {createRequiredValidator} from "@staffpad/webapp-common";
import {DemosFormSection} from "./DemosFormSection";
import {StoreItemCommonFieldsSection} from "./StoreItemCommonFieldsSection";
import {FormFieldText} from "@staffpad/webapp-common";
import {FormFieldTextArea} from "@staffpad/webapp-common";
import {useHistory} from "react-router";

interface RegularItemFormProps {
    regularItem: StoreItemRegularInput
    onSave?: (() => void) | undefined
}

export const RegularItemForm = (props: RegularItemFormProps) => {
    const {onSave} = props

    const validateForm = (values: StoreItemRegularInput): FormikErrors<any> | undefined => {
        console.log("validate form", values)
        let errors: FormikErrors<any> = {}
        if (values.PurchaseId.length === 0) {
            errors.PurchaseId_Array = "Purchase IDs list can not by empty"
        }
        if (values.folderName.length === 0) {
            errors.folderName_Array = "Folder Names list can not by empty"
        }
        return errors
    }

    const history = useHistory()

    const updateOrCreateRegularItemMutation = useUpdateOrCreateRegularItemMutation({
        onSuccess: data => {
            console.log("Item successfully updated", data)
            if (onSave) {
                onSave()
            }
            history.push(`/sounds/${data.store_updateOrCreateRegularItem.id}/general`)
        },
        onError: error => {
            console.error(error)
        }
    })

    return (
        <Formik validateOnChange={false}
                initialValues={props.regularItem}
                validate={validateForm}
                onSubmit={async (values, actions) => {
                    await updateOrCreateRegularItemMutation.mutateAsync({
                        item: values
                    })
                    actions.setSubmitting(false)
                }}
        >
            {(props) => (
                <Form>
                    <VStack spacing={6} alignItems={"start"}>
                        <StoreItemCommonFieldsSection/>
                        <FormFieldText fieldId={"regularDetails.title"}
                                       fieldTitle={"Title"}
                                       validatorFactory={createRequiredValidator}/>
                        <FormFieldText fieldId={"regularDetails.subtitle"}
                                       fieldTitle={"Subtitle"}
                                       validatorFactory={createRequiredValidator}/>
                        <FormFieldTextArea fieldId={"regularDetails.description"}
                                           minHeight={"300px"}
                                           fieldTitle={"Description"}
                                           validatorFactory={createRequiredValidator}/>
                        <FormFieldText fieldId={"regularDetails.instrument"}
                                       fieldTitle={"Instrument"}
                                       validatorFactory={createRequiredValidator}/>
                        <FormFieldText fieldId={"regularDetails.creator"}
                                       fieldTitle={"Creator"}
                                       validatorFactory={createRequiredValidator}/>
                        <DemosFormSection l10nKeyPrefix={"demos"} fieldName={"regularDetails.demos"} fieldTitle={"demo"}/>
                        <Button
                            mt={4}
                            colorScheme="blue"
                            isLoading={props.isSubmitting}
                            type="submit">
                            Save
                        </Button>
                    </VStack>
                </Form>
            )}
        </Formik>
    )
}