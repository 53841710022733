import * as React from 'react';

import {Button, VStack} from "@chakra-ui/react"

import {Form, Formik, FormikErrors} from 'formik';
import {createRequiredValidator} from "@staffpad/webapp-common";
import {FormFieldText} from "@staffpad/webapp-common";
import {useHistory} from "react-router";
import {
    SearchStoreItemsIdTitleDocument,
    SearchStoreItemsIdTitleQuery,
    SearchStoreItemsIdTitleQueryVariables,
    StoreSectionInput,
    useUpdateOrCreateStoreSectionMutation
} from "../../../../graphql";
import {FormFieldBoolean} from "@staffpad/webapp-common";
import {EntityInfo, FormFieldEntityList, IdToTitleMap} from "@staffpad/webapp-common";
import {fetchData} from "../../../../graphql/fetcher";
import _ from "lodash"

interface SoundSectionFormProps {
    section: StoreSectionInput
    onSave?: (() => void) | undefined
}

const fetchTitlesByIds = (): (() => Promise<IdToTitleMap>) => {
    return async () => {
        const fetchTitles = fetchData<SearchStoreItemsIdTitleQuery,
            SearchStoreItemsIdTitleQueryVariables>(SearchStoreItemsIdTitleDocument, {
        })
        const result = await fetchTitles()
        return _.reduce(result.store_itemsSearch.items, (map: any, item)=>{
            map[item.id] = item.title
            return map
        }, {})
    }
}

const fetchSounds = (): (() => Promise<EntityInfo[]>) => {
    return async () => {
        const fetchStoreItems = fetchData<SearchStoreItemsIdTitleQuery,
            SearchStoreItemsIdTitleQueryVariables>(SearchStoreItemsIdTitleDocument, {
        })
        const result = await fetchStoreItems()
        const sorted = _.sortBy(result.store_itemsSearch.items, ["title"])
        return sorted.map((value): EntityInfo => ({
            id: value.id,
            title: value.title ?? ""
        }))
    }
}

export const SoundSectionForm = (props: SoundSectionFormProps) => {
    const onSave = props.onSave

    const validateForm = (values: StoreSectionInput): FormikErrors<any> | undefined => {
        console.log("validate form", values)
        //let errors: FormikErrors<any> = {}
        // if (values.PurchaseId.length === 0) {
        //     errors.PurchaseId_Array = "Purchase IDs list can not by empty"
        // }
        // if (values.folderName.length === 0) {
        //     errors.folderName_Array = "Folder Names list can not by empty"
        // }
        return {}
    }

    const history = useHistory()

    const updateOrCreateRegularItemMutation = useUpdateOrCreateStoreSectionMutation({
        onSuccess: data => {
            console.log("Item successfully updated", data)
            if(onSave) {
                onSave()
            }
            history.push(`/sound-sections/${data.store_updateOrCreateStoreSection.id}`)
        },
        onError: error => {
            console.error(error)
        }
    })

    return (
        <Formik validateOnChange={false}
                initialValues={props.section}
                validate={validateForm}
                onSubmit={async (values: StoreSectionInput, actions) => {
                    console.log("Form value", values)
                    await updateOrCreateRegularItemMutation.mutateAsync({
                        section: values
                    })
                    actions.setSubmitting(false)
                }}
        >
            {(props) => (
                <Form>
                    <VStack spacing={6} alignItems={"start"}>
                        <FormFieldText fieldId={"id"}
                                       fieldTitle={"ID"}
                                       validatorFactory={createRequiredValidator}/>
                        <FormFieldText fieldId={"title"}
                                       isLocalized={true}
                                       l10nStringKeyFieldName={`${props.values.id}/title`}
                                       fieldTitle={"Title"}
                                       validatorFactory={createRequiredValidator}/>
                        <FormFieldBoolean fieldId={"areItemsShuffled"} fieldTitle={"Are items shuffled"}/>
                        <FormFieldEntityList
                            fieldName={"itemIds"}
                            fieldTitle={"Items"}
                            fieldTitleSingular={"Item"}
                            fetchTitlesByIds={fetchTitlesByIds}
                            fetchEntities={fetchSounds}/>
                        <Button
                            mt={4}
                            colorScheme="blue"
                            isLoading={props.isSubmitting}
                            type="submit">
                            Save
                        </Button>
                    </VStack>
                </Form>
            )}
        </Formik>
    )
}