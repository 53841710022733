import * as React from 'react';

import {
    Box,
    Text
  } from "@chakra-ui/react"

export interface IDashBoardPageProps {
  a: string
}

export const DashBoardPage = () => {
  return (
      <Box textAlign="center" fontSize="xl" bg="blue.100">
        <Text>DashBoard Page</Text>
      </Box>
  );
}
