import * as React from 'react';

import {
  Box,
} from "@chakra-ui/react"

import { Sidebar } from "@staffpad/webapp-common"
import { SidebarItem } from "@staffpad/webapp-common"
import { Route } from "react-router-dom"
import { AppsPage } from "./apps/AppsPage"
import { EffectsPage } from "./effects/EffectsPage"
import { ElementsPage } from './elements/ElementsPage';
import { ServicesPage } from './services/ServicesPage';
import { SoundsPage } from './sounds/SoundsPage'; 
import { SubscriptionsPage } from './subscriptions/SubscriptionsPage';
import { UploadElementsPage } from './elements/UploadElementsPage';

const ProductsPage = () => {

  const sidebarItems: SidebarItem[] = [
    { title: "Apps", mainPath: "/products/apps", relatedPaths:[] },
    { title: "Subscriptions", mainPath: "/products/subscriptions", relatedPaths:[] },
    { title: "Sounds", mainPath: "/products/sounds", relatedPaths:["/sound-sections"] },
    { title: "Effects", mainPath: "/products/effects", relatedPaths:[] },
    { title: "Services", mainPath: "/products/services", relatedPaths:[] },
    { title: "Elements", mainPath: "/products/elements", relatedPaths:[] },
  ]

  return (
    <Box as="main" className="main-content" w="full" maxW="8xl" mx="auto">
      <Box display={{ md: "flex" }}>
        <Sidebar items={sidebarItems} />
        <Box pl="2rem" ml="1rem" flex="1" minW="0" pt="2rem">
          <Route path="/products/apps" component={AppsPage}/>
          <Route path="/products/subscriptions" component={SubscriptionsPage}/>
          <Route path="/products/sounds" exact component={SoundsPage}/>
          <Route path="/products/effects" component={EffectsPage}/>
          <Route path="/products/services" component={ServicesPage}/>
          <Route path="/products/elements" exact component={ElementsPage}/>
          <Route path="/products/elements/upload" component={UploadElementsPage}/>
        </Box>
      </Box>
    </Box>
  );
}

export {ProductsPage}
